import styled from "styled-components"
import { Grid, Button, Fab } from "@mui/material"
import Card from "@mui/material/Card";

export const ProductGrid = styled(Grid)`
  padding-top: 5px;

  @media (max-width: 1300px){
    flex-direction: column !important;
  }

  :hover {
    cursor: pointer;
  }

  .productPhoto {
    border-radius: 12px;
  }
`

export const TableCard = styled(Card)`
  padding: 0 22px !important;
  
  .filterIcon {
    @media (max-width: 1600px) {
      margin-right: 18px;
    }
    @media (max-width: 992px) {
      margin-right: 0px;
    }
    margin-right: 24px;
  }
`

export const ProductTextGrid = styled(Grid)`

  padding-top: 5px;
  
/*   @media (min-width: 1300px) {
    margin-left: 20px !important;
  } */
  .name{
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
  }
`
export const ThemeGrid = styled(Grid)`
  .name{
    font-weight: 700;
    color: #ffffff;
    font-size: 16px;
  }

  .tags{
    font-size: 14px;
  }
`

export const StatusGrid = styled(Grid)`
  align-items: center;
  gap: 10px;
  flex-wrap: nowrap !important;

  .status {
    font-weight: 500;
    color: #ffffff;
    font-size: 12px;
  }
`

export const SocialsGrid = styled(Grid)`
  gap: 20px;

  @media (max-width: 992px) {
    flex-wrap: wrap !important;
    justify-content: center;
  }

  .active {
    img {
      cursor: pointer;
    }
  }

  .disabled {
    filter: grayscale(1);
  }
`

export const ReachGrid = styled(Grid)`
  margin-top: 15px;
  flex-wrap: nowrap !important;
`

export const EditButton = styled(Button)`
  width: 41px !important;
  min-width: 30px !important;
  height: 41px !important;
  border-radius: 12px !important;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 100%) !important;
`

export const RankingPhoto = styled(Grid)`
  .photoContainer {
    overflow: hidden;
    border-radius: 10px;
    width: 45px;
    height: 50px;
    margin-top: 5px;

    img {
      width: 100%;
      height: 100%;
      text-align: center;
      object-fit: cover;
      color: transparent;
      text-indent: 10000px;
      height: auto;
    }
  }
`

export const ModalFab = styled(Fab)`
  position: fixed !important;
  bottom: 50px;
  right: 0px;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  transition: all .2s ease-in-out !important;
  margin-right: 30px !important;
    
  &:hover{
    transform: scale(1.3);
  }
`

export const OptionsContainer = styled(Grid)`
  position: absolute;
  right: 50px;
`

export const OptionsGrid = styled(Grid)`
  border: 1px solid grey;
  padding: 10px;
  gap: 10px;
  background: #000;
  border-radius: 10px;
  z-index: 999 !important;

  p{
    cursor: pointer;
  }

  p:hover{
    color: #FC6009;
  }
`

export const Divider = styled.hr`
  border: 0;
  height: 1px;
  background: #2D3748;
`

export const PaginationGrid = styled(Grid)`
  .MuiPaginationItem-root {
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0;
    cursor: pointer;
    vertical-align: middle;
    -moz-appearance: none;
    -webkit-appearance: none;
    -webkit-text-decoration: none;
    text-decoration: none;
    font-family: "Plus Jakarta Display","Helvetica","Arial",sans-serif;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    line-height: 1.4;
    text-align: center;
    text-transform: unset;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-size: 150%!important;
    background-position-x: 25%!important;
    transition: all 150ms ease-in;
    background: transparent;
    color: #a0aec0;
    border-color: #a0aec0;
    border-radius: 10rem;
    padding: 0.6875rem 0.6875rem 0.625rem;
    margin: 0 0.125rem;
    pointer-events: auto;
    font-weight: 400;
    font-size: 0.875rem;
    width: 2.875rem;
    min-width: 2.875rem;
    height: 2.875rem;
    min-height: 2.875rem;

    &:hover {
      background-color: #e9ecef;
    }
  }

  .MuiPaginationItem-root.Mui-selected {
    background: linear-gradient(180deg, #FB8404, #FE1E14) !important;
    border: none;
    color: white;
  }
`