import styled from "styled-components";
import { Grid, TextField, Fab, Button } from "@mui/material";

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const Input = styled(TextField)`
  background-color: transparent;
  /* border: 0.4px solid rgba(255, 255, 255, 0.50) !important; */
  border-radius: 10px;
  width: 100%;
  min-height: 40px;
`

export const AlertGrid = styled(Grid)`
  p {
    color: #8F8F8F;
    font-weight: 400;
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
  }
`

export const ImageUploadContainer = styled(Grid)`
  height: 150px;
  border-radius: 10px;
  cursor: pointer;
  place-content: center;

  .previewImg{
    max-width: 70%;
    max-height: 70%;
  }

`

export const ImageUploadGrid = styled(Grid)`
  opacity: 0.3;
`

export const DeleteFab = styled(Fab)`
  justify-self: right;
  background: linear-gradient(180deg, #FB8404 0%, #FE1E14 155.15%), #C4C4C4;
  
  &:hover{
    transform: scale(1.1);
  }
`