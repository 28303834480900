// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import * as S from './styles/style'
import { NavLink } from "react-router-dom";
import { FaCircle } from "react-icons/fa6";
import { Grid } from "@mui/material";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";

// Vision UI Dashboard React components
import VuiBox from "components/VuiBox";

// Custom styles for the SidenavCollapse
import {
  collapseItem,
  collapseIconBox,
  collapseIcon,
  collapseText,
} from "defaultComponents/Sidenav/styles/sidenavCollapse";

// Vision UI Dashboard React context
import { useVisionUIController } from "context";

function SidenavCollapse({ 
  color = "info", 
  icon, 
  name, 
  children, 
  active = false, 
  noCollapse = false, 
  open = false, 
  subRoutes, 
  route, 
  routeKey, 
  subRouteActive, 
  ...rest 
}) {
  const [controller] = useVisionUIController();
  const { miniSidenav, transparentSidenav } = controller;

  return (
    <>
      <NavLink to={route} key={routeKey}>
        <ListItem component="li">
          <VuiBox {...rest} sx={(theme) => collapseItem(theme, { active, transparentSidenav })}>
            <ListItemIcon
              sx={(theme) => collapseIconBox(theme, { active, transparentSidenav, color })}
            >
              {typeof icon === "string" ? (
                <Icon sx={(theme) => collapseIcon(theme, { active })}>{icon}</Icon>
              ) : (
                icon
              )}
            </ListItemIcon>
            <ListItemText
              primary={name}
              sx={(theme) => collapseText(theme, { miniSidenav, transparentSidenav, active })}
            />
          </VuiBox>
        </ListItem>
      </NavLink>
      {active && subRoutes &&
        <S.ChildrenContainer container flexDirection='column'>
          {subRoutes?.map((subRoute) => {
            const isActive = subRoute?.key === subRouteActive;
            return (
              <S.SubRouteLink
                to={subRoute.route}
                key={subRoute.key}
                style={isActive ? { background: "linear-gradient(92.85deg, rgba(255, 255, 255, 0.2) 0.38%, rgba(239, 241, 246, 0) 144.37%)" } : null}>
                <Grid container gap='10px'>
                  <FaCircle color="#D85308" size='6px' style={{ alignSelf: "center" }} />
                  <p style={{ color: "white", fontSize: "14px" }}>{subRoute.name}</p>
                </Grid>
              </S.SubRouteLink>
            )
          })}
        </S.ChildrenContainer>
      }
      {/*       {children && (
        <Collapse in={true} unmountOnExit>
          {children}
        </Collapse>
      )} */}
    </>
  );
}

// Typechecking props for the SidenavCollapse
SidenavCollapse.propTypes = {
  color: PropTypes.oneOf(["info", "success", "warning", "error", "dark", "primary"]),
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  active: PropTypes.bool,
  noCollapse: PropTypes.bool,
  open: PropTypes.bool,
};

export default SidenavCollapse;
