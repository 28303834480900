import { useState, useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import { Grid } from '@mui/material';
import * as S from './style'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { RiCalendarEventFill } from 'react-icons/ri'
import InputAdornment from '@mui/material/InputAdornment';
import Modal from 'components/Modal/Modal';
import moment from 'moment';
import 'moment/locale/pt-br';
import MaskMoney from 'components/Masks/MaskMoney';
import showToast from 'components/Toast/Toast';
import FormHelperText from '@mui/material/FormHelperText';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { IoAdd } from "react-icons/io5";
import VuiAvatar from "components/VuiAvatar";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import { curves, branches } from 'utils/utils';
import { createBriefingProject } from 'utils/requests/project';
import { createSimpleLead } from 'utils/requests/leads';
import { getAllInfluencers } from 'utils/requests/auth';
import { getAllClients } from 'utils/requests/leads';
import { createInternalProject } from 'utils/requests/project';
import { useNavigate } from 'react-router-dom';

const AddProject = () => {
  moment.locale("pt-br");

  const navigate = useNavigate();

  const [newLeadOpen, setNewLeadOpen] = useState(false);
  const [activeInfluencers, setActiveInfluencers] = useState();
  const [leadsArr, setLeadsArr] = useState();
  const [validateFields, setValidateFields] = useState(false);
  const [validateLeadFields, setValidateLeadFields] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: '',
      projectType: null,
      lead: '',
      influencers: [],
      description: '',
    },

    validateOnChange: validateFields,
    validateOnBlur: false,

    validationSchema: Yup.object({
      title: Yup.string().required("Título é um campo obrigatório"),
      projectType: Yup.object().required("Tipo de projeto é obrigatório"),
      influencers: Yup.array().min(1, "Necessário pelo menos um influencer vinculado"),
      lead: Yup.object().required("Empresa é um campo obrigatório"),
      description: Yup.string().required("Descrição é um campo obrigatório"),
    }),
    onSubmit: async (values) => {
      const newProject = {
        title: values.title ?? '',
        influencers: values.influencers ? values.influencers.map((influencer) => influencer?.id) : '',
        description: values.description ?? '',
        type: values?.projectType?.value ?? '',
        status: 'ONGOING',
        currency: 'BRL',
        pipeline: {
          leadsRelated: values.lead.id ?? '',
        },
        teamTargeted: 'INTERNAL'
      };
      Object.keys(newProject).forEach(key => {
        if (typeof newProject[key] === 'object' && Object.keys(newProject[key]).length > 0) {
          Object.entries(newProject[key]).forEach(entry => {
            if (entry[1] === '') {
              delete newProject[key][entry[0]];
            }
          })
        }
        if (typeof newProject[key] === 'object' && Object.keys(newProject[key]).length === 0) {
          delete newProject[key];
        };
        if ((Array.isArray(newProject[key])) && (newProject[key].length === 0)) {
          delete newProject[key];
        };
        if (newProject[key] === '') {
          delete newProject[key];
        };
      });

      await createInternalProject(newProject).then((res) => {
        if (!res.message) {
          showToast("Projeto criado com sucesso, redirecionando...", "success");
          setTimeout(() => {
            navigate(`/marketing/projetos/${res?.id ? res?.id : ""}`);
          }, 3000);
        };
        if (res.message) { showToast("Falha na criação de projeto", "error") };
      });
    }
  });

  /*   const leadFormik = useFormik({
      enableReinitialize: true,
  
      validateOnChange: validateLeadFields,
      validateOnBlur: false,
  
      initialValues: {
        businessName: '',
        businessDescription: '',
        branch: '',
        curve: '',
      },
  
      validationSchema: Yup.object({
        businessName: Yup.string().required('Nome da empresa é obrigatório'),
        businessDescription: Yup.string().required('Descrição é obrigatório'),
        branch: Yup.string().required('Ramo é obrigatório'),
        curve: Yup.string().required('Curva é obrigatório'),
      }),
      onSubmit: async (values) => {
        const clientValues = {
          businessName: values.businessName ? values.businessName : '',
          businessDescription: values.businessDescription ? values.businessDescription : '',
          branch: values.branch ? values.branch : '',
          curve: values.curve ? values.curve : '',
        };
  
        Object.keys(clientValues).forEach(key => {
          if (clientValues[key] === '') {
            delete clientValues[key];
          };
        });
  
        if (Object.keys(clientValues).length > 0) {
          await createSimpleLead(clientValues).then((res) => {
            if (res?.message) {
              showToast('Falha na criação de empresa', 'error');
            };
            if (!res?.message) {
              showToast('Empresa criada com sucesso!', 'success');
              setNewLeadOpen(false);
              leadFormik.resetForm();
              fetchLeads();
              formik.setFieldValue('lead', res);
            };
          });
        }
        if (Object.keys(clientValues).length === 0) showToast('Sem dados alterados', 'warning');
  
      }
    }); */

  const getActiveInfluencers = async () => {
    const data = await getAllInfluencers({ onlyActive: true });
    if (!data.message) {
      setActiveInfluencers(data);
    };
  };

  const fetchLeads = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setLeadsArr(data);
    };
  };

/*   const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  }; */

  /*   const handleNewLeadClose = () => {
      setNewLeadOpen(false);
      formik.setFieldValue('lead', null);
      leadFormik.resetForm();
    }; */

  useEffect(() => {
    fetchLeads();
    getActiveInfluencers();
  }, []);

  /*   useEffect(() => {
      if (formik?.values?.lead?.id === 'Criar nova empresa +') {
        setNewLeadOpen(true);
      };
    }, [formik.values.lead]); */

  flatpickr("#date", flatpickrOptions('endDate', formik.setFieldValue, formik.setFieldTouched));
  flatpickr(".paymentForeseenDate", flatpickrOptions('paymentDate', formik.setFieldValue, formik.setFieldTouched));

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  /* const filter = createFilterOptions(); */

  const statusList = [
    {
      label: 'Campanha',
      value: 'CAMPANHA'
    },
    {
      label: 'Palco',
      value: 'PALCO'
    },
    {
      label: 'Eventos',
      value: 'EVENTOS'
    },
    {
      label: 'Audio Visual',
      value: 'AUDIOVISUAL'
    },
    {
      label: 'Livro',
      value: 'LIVRO'
    },
  ];

  return (
    <>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit} validateOnChange validateOnBlur validateOnMount>
        <Form style={{ width: '100%' }}>
          <p>Criar Projeto Interno</p>
          <Grid container>
            <Grid container flexDirection='column' gap='20px' marginTop='30px'>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='title'
                    id='title'
                    hiddenLabel
                    type='text'
                    placeholder="Título"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.title}
                    error={Boolean(formik.errors.title) && formik.touched.title}
                    helperText={formik.touched.title ? formik.errors.title : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id='projectType'
                    value={formik.values?.projectType ? formik.values?.projectType : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('projectType', value)
                    }}
                    options={statusList}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option?.label}
                    renderInput={(params) =>
                      <S.Input
                        {...params}
                        placeholder='Tipo de Projeto'
                        error={Boolean(formik.errors.projectType)}
                        helperText={formik.errors.projectType}
                      />
                    }
                    isOptionEqualToValue={(option, value) => option?.value === value?.value}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    disableClearable
                    id='lead'
                    value={formik.values?.lead ? formik.values?.lead : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('lead', value)
                    }}
                    options={leadsArr ? leadsArr[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) =>
                      <S.Input
                        {...params}
                        placeholder='Empresa'
                        error={Boolean(formik.errors.lead)}
                        helperText={formik.errors.lead}
                      />
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  /*                     filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                  
                                        filtered.push({
                                          businessName: "Criar nova empresa +", id: "Criar nova empresa +"
                                        });
                  
                                        return filtered;
                                      }} */
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    id="influencers"
                    name="influencers"
                    disablePortal
                    disableCloseOnSelect
                    disableClearable
                    multiple
                    value={formik.values.influencers ? formik.values.influencers : []}
                    onChange={(e, value) => {
                      formik.setFieldValue('influencers', value)
                    }}
                    options={activeInfluencers ? activeInfluencers[0] : []}
                    sx={{ width: "100%" }}
                    renderInput={(params) =>
                      <S.Input
                        {...params}
                        placeholder='Influencers'
                        error={Boolean(formik.errors.influencers)}
                        helperText={formik.errors.influencers}
                      />
                    }
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    hiddenLabel
                    name='description'
                    id='description'
                    type='text'
                    placeholder="Descrição"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    error={Boolean(formik.errors.description)}
                    helperText={formik.errors.description}
                  />
                </S.InputGrid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              id='save'
              sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
      {/*       <Modal
        open={newLeadOpen}
        onClose={handleNewLeadClose}
        size={"sm"}
      >
        <Grid container flexDirection='column' gap='30px'>
          <p>Nova Empresa</p>
          <Formik initialValues={leadFormik.initialValues} validationSchema={leadFormik.validationSchema} onSubmit={leadFormik.handleSubmit}>
            <Form style={{ width: '100%' }}>
              <Grid container flexDirection='column' gap='15px'>
                <S.InputGrid item xl={12}>
                  <S.Input
                    fullWidth
                    name='businessName'
                    id='businessName'
                    placeholder='Nome da empresa'
                    onChange={leadFormik.handleChange}
                    onBlur={leadFormik.handleBlur}
                    value={leadFormik.values.businessName}
                    error={(Boolean(leadFormik.errors.businessName))}
                    helperText={leadFormik.errors.businessName}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='branch'
                    id='branch'
                    disablePortal
                    value={leadFormik.values.branch ? leadFormik.values.branch : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('branch')
                      leadFormik.setFieldValue('branch', value ? value : '')
                    }}
                    options={branches ? branches : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Ramo' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.branch) && leadFormik.touched.branch) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Ramo é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='curve'
                    id='curve'
                    disablePortal
                    value={leadFormik.values.curve ? leadFormik.values.curve : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('curve')
                      leadFormik.setFieldValue('curve', value ? value : '')
                    }}
                    options={curves ? curves : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Curva' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.curve) && leadFormik.touched.curve) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Curva é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <S.Input
                    fullWidth
                    name='businessDescription'
                    id='businessDescription'
                    placeholder='Descrição'
                    onChange={leadFormik.handleChange}
                    onBlur={leadFormik.handleBlur}
                    value={leadFormik.values.businessDescription}
                    error={(Boolean(leadFormik.errors.businessDescription))}
                    helperText={leadFormik.errors.businessDescription}
                  />
                </S.InputGrid>
                <Grid container justifyContent='flex-end'>
                  <ButtonCustom
                    label='Salvar'
                    type='submit'
                    sx={{ width: "100px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
                    onClick={() => setValidateLeadFields(true)}
                  />
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Modal> */}
    </>
  )
}

export default AddProject