import { useState, useContext, useEffect, useRef } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, Card, Box } from "@mui/material";
import Board from "react-trello";
import Modal from "components/Modal/Modal";
import * as S from "./style";
import { BsQuestionLg } from "react-icons/bs";
import CardModal from "./CardModal/CardModal";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Tag from "react-trello/dist/components/Card/Tag";
import moment from "moment";
import "moment/locale/pt-br";
import { BsClock } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import showToast from "components/Toast/Toast";
import * as T from "components/Toast/style";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import Autocomplete from "@mui/material/Autocomplete";
import { TokenContext } from "context/TokenContext";
import { RiAddFill } from "react-icons/ri";
import CreateCardModal from "./CreateCardModal/CreateCardModal";
import { useNavigate } from "react-router-dom";
import { getPipelineBoard } from "utils/requests/project";
import { useSearchParams } from "react-router-dom";
import { editMarketingCard } from "utils/requests/project";
import { deleteMarketingCard } from "utils/requests/project";
import test from "assets/images/avatar1.png";
import AvatarGroup from "components/AvatarGroup/AvatarGroup";
import { getUsersByUserTag } from "utils/requests/auth";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#e0e0e0",
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const MarketingKanban = () => {
  const navigate = useNavigate();
  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;
  const marketingRoles = decodedToken?.tags?.map((tag) => tag);

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTeam = searchParams.get("team") ?? "";

  const teams = [
    {
      name: "Geral",
      tag: "",
    },
    {
      name: "Designers",
      tag: "DESIGNER",
    },
    {
      name: "Videomakers",
      tag: "FILMMAKER",
    },
    {
      name: "Social Media",
      tag: "SOCIAL MEDIA",
    },
    {
      name: "Produção",
      tag: "PRODUCER",
    },
  ];

  const filteredTeamsByRole = teams.filter((team) => marketingRoles?.includes(team.tag));
  const teamsWithGeneral = [teams[0], filteredTeamsByRole]?.flat();

  const [openCardModal, setOpenCardModal] = useState(false);
  const [inlineEdit, setInlineEdit] = useState(false);
  const [boardData, setBoardData] = useState({ lanes: [] });
  const [cardId, setCardId] = useState("");
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [chosenTeam, setChosenTeam] = useState(
    selectedTeam ? teams.find((team) => team.tag === selectedTeam) : teams[0]
  );
  const [openCreateCardModal, setOpenCreateCardModal] = useState(false);
  const [teamMembers, setTeamMembers] = useState([]);

  const fetchData = async () => {
    const data = await getPipelineBoard(chosenTeam?.tag);
    if (!data.message) {
      setBoardData(data);
    }
  };

  const fetchTeamMembers = async () => {
    if (chosenTeam?.name !== "Geral") {
      const data = await getUsersByUserTag([chosenTeam?.tag]);
      if (!data.message) {
        setTeamMembers(data);
      }
      return;
    }
    setTeamMembers([]);
  };

  useEffect(() => {
    if (!teams.find((team) => team.tag === selectedTeam)) {
      navigate("/404");
      return;
    }
    if (selectedTeam !== chosenTeam?.tag) {
      navigate(
        chosenTeam?.tag
          ? `/marketing/pipeline/kanban?team=${chosenTeam?.tag}`
          : `/marketing/pipeline/kanban`
      );
    }
    fetchData();
    fetchTeamMembers();
  }, [chosenTeam]);

  const FLAT_TRANSLATION_TABLE = {
    "Add another lane": "+ Adicionar lista",
    "Click to add card": "+ Novo Card",
    "Delete lane": "Deletar lista",
    "button.Add lane": "Adicionar lista",
    "button.Add card": "Novo card",
    "button.Cancel": "Cancelar",
    "placeholder.title": "Título",
    "placeholder.description": "Descrição",
    "placeholder.label": "Label",
  };

  const laneExplanations = {
    "Leads 🔥": "Empresas que demonstraram interesse no negócio",
    "Relacionamento 🎯": "Lead sendo preparado para conversão",
    "FUP - Leads 🆙": "Momento de fornecer informações adicionais e esclarecer dúvidas.",
    "Em Negociação 💰": "Valores do négocio sendo negociados com o cliente.",
    "Contratos Fechados 🤝": "Negociação aprovada com prazo paga pagamento e execução do projeto",
  };

  const CustomLaneHeader = ({ label, cards, title, current, target }) => {
    return (
      <div>
        <header
          style={{
            paddingBottom: 6,
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontSize: 14, fontWeight: "bold" }}>{title}</div>
          <LightTooltip title={laneExplanations[title]}>
            <div style={{ width: "fit-content", textAlign: "right", fontSize: 13 }}>
              <BsQuestionLg style={{ backgroundColor: "#000" }} />
            </div>
          </LightTooltip>
        </header>
      </div>
    );
  };

  const deleteCard = async (id) => {
    await deleteMarketingCard(id).then((res) => {
      if (res.title) {
        showToast("Card deletado com sucesso!", "success");
        fetchData();
        setOpenCardModal(false);
      } else {
        showToast("Falha ao deletar card", "error");
      }
    });
  };

  const changeCardStatus = async (newStatus, id) => {
    const validMovement = boardData?.lanes?.map((lane) => {
      const res = lane?.cards?.find((card) => card.id === id);
      if (res) {
        return res.laneId !== newStatus;
      }
    });
    if (validMovement?.includes(true)) {
      const normalizedStatus = String(newStatus).replace(/-/g, "_").toUpperCase();
      await editMarketingCard(id, { status: normalizedStatus }).then((res) => {
        if (res.title) {
          fetchData();
        } else {
          showToast("Erro inesperado ao mover card", "error");
        }
      });
    }
  };

  const CustomCard = ({
    onClick,
    title,
    subTitle,
    tagStyle,
    tags,
    onDelete,
    description,
    dueDate,
  }) => {
    const clickDelete = (e) => {
      onDelete();
      e.stopPropagation();
    };

    const actualTime = new Date().getTime();
    const timeComparison = moment(dueDate, "YYYY-MM-DD HH:mm").valueOf() - actualTime;

    return (
      <S.CardWrapper onClick={onClick}>
        <S.CardHeader
          style={{
            paddingBottom: 6,
            marginBottom: 10,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            color: "#000",
            backgroundColor: "#000",
          }}
        >
          <S.CardTitle>{title}</S.CardTitle>
          {timeComparison > 86400000 && (
            <S.DueDate>
              <p>{dueDate ? moment(dueDate, "YYYY-MM-DD HH:mm").format("DD [de] MMM") : "-"}</p>
            </S.DueDate>
          )}
          {timeComparison > 0 && timeComparison <= 86400000 && (
            <S.DueDateWarning>
              <BsClock size="12px" />
              <p>{dueDate ? moment(dueDate, "YYYY-MM-DD HH:mm").format("DD [de] MMM") : "-"}</p>
            </S.DueDateWarning>
          )}
          {timeComparison < 0 && (
            <S.DueDateDelayed>
              <IoWarningOutline size="16px" />
              <p>{dueDate ? moment(dueDate, "YYYY-MM-DD HH:mm").format("DD [de] MMM") : "-"}</p>
            </S.DueDateDelayed>
          )}
          {/* {showDeleteButton && <DeleteButton onClick={clickDelete} />} */}
        </S.CardHeader>
        <div style={{ fontSize: 12, color: "#BD3B36" }}>
          <div style={{ color: "#4C4C4C", fontWeight: "bold", backgroundColor: "#000" }}>
            {subTitle}
          </div>
          <S.Detail>
            <p>{description?.length > 60 ? `${description?.slice(0, 250)}...` : description}</p>
          </S.Detail>
          {tags && (
            <S.TagGrid
              style={{
                paddingTop: 6,
                display: "flex",
                justifyContent: "flex-end",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {tags.map((tag) => (
                <Tag key={tag.title} {...tag} tagStyle={tagStyle} />
              ))}
            </S.TagGrid>
          )}
        </div>
      </S.CardWrapper>
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar
        customRoute={role === "MARKETING" ? ["marketing", ""] : []}
        customTitle="Pipe de Produção"
      />
      <S.MainContainer container spacing="18px">
        <T.ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <Card sx={{ width: "100%", paddingTop: "40px", minHeight: "800px" }}>
          <S.SelectInputGrid container item xs={12} md={12} xl={12}>
            <Grid>
              <Autocomplete
                disablePortal
                value={chosenTeam ? chosenTeam : null}
                onChange={(e, value) => setChosenTeam(value)}
                options={role === "MARKETING" ? teamsWithGeneral : teams}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <S.Input {...params} placeholder="Time" />}
                isOptionEqualToValue={(option, value) => option.tag === value.tag}
                disableClearable
                sx={{ minWidth: "250px" }}
              />
            </Grid>
            <Grid display="flex">
              <AvatarGroup
                members={
                  teamMembers[0]?.length > 0
                    ? teamMembers[0]?.map((member) => [member?.avatar, member?.name])
                    : []
                }
              />
            </Grid>
          </S.SelectInputGrid>
          {boardData?.lanes?.length === 0 && (
            <Grid mt="20px" pl="10px">
              <p>{"Sem dados para exibir"}</p>
            </Grid>
          )}
          {boardData && (
            <Board
              components={{ LaneHeader: CustomLaneHeader, Card: CustomCard }}
              editLaneTitle={inlineEdit}
              editable={true}
              hideCardDeleteIcon
              draggable={inlineEdit}
              canAddLanes={inlineEdit}
              data={boardData}
              t={(key) => FLAT_TRANSLATION_TABLE[key]}
              style={{ backgroundColor: "black", color: "#fff", height: "80vh", overflow: "auto" }}
              cardStyle={{ backgroundColor: "#0A0A0A", color: "#fff" }}
              laneStyle={{ backgroundColor: "#101010", color: "#fff" }}
              /* onCardUpdate={(params, data) => console.log(params, data)} */
              onCardAdd={(params) => console.log(params)}
              /*             onLaneUpdate={(laneId, data) => console.log(laneId, data)} */
              /* onDataChange={(newData) => console.log(newData)} */
              onCardMoveAcrossLanes={(fromLaneId, toLaneId, cardId, index) => {
                changeCardStatus(toLaneId, cardId);
              }}
              onCardClick={(cardId, metadata, laneId, data) => {
                /* console.log(cardId, metadata, laneId); */ setOpenCardModal(true);
                setCardId(cardId);
              }}
            />
          )}
        </Card>
      </S.MainContainer>
      <Modal
        open={openCardModal}
        onClose={() => {
          setOpenCardModal(false);
          setCardId("");
        }}
        deleteFunc={() => setOpenConfirmDeletionModal(true)}
      >
        <CardModal
          cardId={cardId}
          refetchBoard={fetchData}
          commercialId={chosenTeam?.id}
          closeModal={() => {
            setOpenCardModal(false);
            setCardId("");
          }}
          selectedTeam={chosenTeam}
          userTeams={filteredTeamsByRole}
        />
      </Modal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar Exclusão</p>
          <p style={{ fontSize: "18px" }}>
            Tem certeza que deseja confirmar excluir essa atividade?
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => setOpenConfirmDeletionModal(false)}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              />
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => {
                  deleteCard(cardId);
                  setOpenConfirmDeletionModal(false);
                }}
                sx={{ background: "linear-gradient(180deg, #E55542 0%, #D7007B 154.17%)" }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openCreateCardModal} onClose={() => setOpenCreateCardModal(false)}>
        <CreateCardModal
          closeModal={() => setOpenCreateCardModal(false)}
          refetchBoard={fetchData}
          selectedTeam={chosenTeam}
          userTeams={filteredTeamsByRole}
        />
      </Modal>
      <Box sx={{ "& > :not(style)": { m: 1 } }}>
        <S.ModalFab color="primary" onClick={() => setOpenCreateCardModal(true)}>
          <RiAddFill size="30px" />
        </S.ModalFab>
      </Box>
    </DashboardLayout>
  );
};

export default MarketingKanban;
