import Modal from "components/Modal/Modal"
import { Grid } from "@mui/material"
import * as S from './style'
import placeholderImg from "assets/placeholder.png"
import VuiAvatar from "components/VuiAvatar";
import { IoAdd } from "react-icons/io5";
import { useState, useEffect, useContext } from "react";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { IoIosArrowDown } from "react-icons/io";
import flatpickr from "flatpickr";
import moment from 'moment-timezone';
import 'moment/locale/pt-br';
import { Portuguese } from "flatpickr/dist/l10n/pt"
import confirmDatePlugin from "flatpickr/dist/plugins/confirmDate/confirmDate"
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import TagsModal from "./components/TagsModal";
import MembersModal from "./components/MembersModal";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import showToast from "components/Toast/Toast";
import { TokenContext } from 'context/TokenContext';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import { createSimpleLead } from "utils/requests/leads";
import { branches, curves } from "utils/utils";
import { getAllInfluencers } from "utils/requests/auth";
import * as T from "components/Toast/style";
import { getProjectsFromLeads } from 'utils/requests/project';
import { createPipelineCard } from "utils/requests/project";
import { getAllClients } from "utils/requests/leads";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#e0e0e0',
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const Avatar = ({ img, theme, disabled, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="sm"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      border: `${borderWidth[2]} solid ${rgba(light.focus, 0.3)}`,
      cursor: disabled ? "default" : "pointer",
      position: "relative",
      opacity: disabled ? "0.5" : "1",
      "&:hover, &:focus": {
        zIndex: "10",
      },
    })}
  >
    {children}
  </VuiAvatar>
)

const CreateCardModal = ({ refetchBoard, closeModal, selectedTeam, userTeams }) => {
  const [openMembersModal, setOpenMembersModal] = useState(false);
  const [openTagModal, setOpenTagModal] = useState(false);
  const [cardData, setCardData] = useState();
  const [activeInfluencers, setActiveInfluencers] = useState();
  const [leadsArr, setLeadsArr] = useState();
  const [newLeadOpen, setNewLeadOpen] = useState(false);
  const [projects, setProjects] = useState([]);
  const [validateFields, setValidateFields] = useState(false);

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const teams = [
    {
      name: 'Designers',
      tag: 'DESIGNER',
    },
    {
      name: 'Videomakers',
      tag: 'FILMMAKER'
    },
    {
      name: 'Social Media',
      tag: 'SOCIAL_MEDIA'
    },
    {
      name: 'Produção',
      tag: 'PRODUCER'
    }
  ];

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      title: '',
      dueDate: '',
      tags: [],
      description: '',
      influencers: [],
      lead: null,
      members: [],
      team: selectedTeam?.tag ? selectedTeam : null,
      project: null
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Título é um campo obrigatório"),
      dueDate: Yup.string(),
      members: Yup.array().min(1, 'Necessário pelo menos um membro vinculado'),
      tags: Yup.array().notRequired(),
      description: Yup.string().notRequired(),
      influencers: Yup.array(),
      lead: Yup.object().required("Empresa é um campo obrigatório"),
      team: Yup.object().required("Time é um campo obrigatório"),
      project: Yup.object().required("Projeto é obrigatório")
    }),
    onSubmit: async (values) => {

      const unixDate = (date) => {
        return Number(moment(date, 'YYYY/MM/DD HH:mm').valueOf());
      };

      const newCard = {
        title: values.title ?? '',
        dueDateMs: values.dueDate ? unixDate(values.dueDate) : '',
        members: values.members ? values.members.map((member) => member?.id) : '',
        tags: values.tags.length > 0 ? values.tags : '',
        description: values.description ?? '',
        lead: values.lead.id ?? '',
        team: values.team.tag ?? '',
        project: values.project.id ?? '',
      };

      Object.keys(newCard).forEach(key => {
        if (typeof newCard[key] === 'object' && Object.keys(newCard[key]).length > 0) {
          Object.entries(newCard[key]).forEach(entry => {
            if (entry[1] === '') {
              delete newCard[key][entry[0]];
            }
          })
        }
        if (typeof newCard[key] === 'object' && Object.keys(newCard[key]).length === 0) {
          delete newCard[key];
        };
        if ((Array.isArray(newCard[key])) && (newCard[key].length === 0)) {
          delete newCard[key];
        };
        if (newCard[key] === '') {
          delete newCard[key];
        };
      });

      createPipelineCard(newCard).then((res) => {
        if (!res.message) { showToast("Card criado com sucesso", "success"); refetchBoard(); closeModal() };
        if (res.message) { showToast("Falha na criação de card", "error") };
      });
    }
  });

  const leadFormik = useFormik({
    enableReinitialize: true,

    initialValues: {
      businessName: '',
      businessDescription: '',
      branch: '',
      curve: '',
    },

    validationSchema: Yup.object({
      businessName: Yup.string().required('Nome da empresa é obrigatório'),
      businessDescription: Yup.string().required('Descrição é obrigatório'),
      branch: Yup.string().required('Ramo é obrigatório'),
      curve: Yup.string().required('Curva é obrigatório'),
    }),
    onSubmit: async (values) => {
      const clientValues = {
        businessName: values.businessName ? values.businessName : '',
        businessDescription: values.businessDescription ? values.businessDescription : '',
        branch: values.branch ? values.branch : '',
        curve: values.curve ? values.curve : '',
      };

      Object.keys(clientValues).forEach(key => {
        if (clientValues[key] === '') {
          delete clientValues[key];
        };
      });

      if (Object.keys(clientValues).length > 0) {
        await createSimpleLead(clientValues).then((res) => {
          if (res?.message) {
            showToast('Falha na criação de empresa', 'error');
          };
          if (!res?.message) {
            showToast('Empresa criada com sucesso!', 'success');
            setNewLeadOpen(false);
            leadFormik.resetForm();
            fetchLeads();
            formik.setFieldValue('lead', res);
          };
        });
      }
      if (Object.keys(clientValues).length === 0) showToast('Sem dados alterados', 'warning');

    }
  });

  const getActiveInfluencers = async () => {
    const data = await getAllInfluencers({ onlyActive: true });
    if (!data.message) {
      setActiveInfluencers(data);
    };
  };

  const fetchLeads = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setLeadsArr(data);
    };
  };

  const fetchProjects = async () => {
    if (formik?.values?.lead?.id) {
      const data = await getProjectsFromLeads([formik?.values?.lead?.id], ['ONGOING']);
      if (!data.message) {
        setProjects(data);
      };
    } else {
      setProjects([])
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [formik.values.lead]);

  useEffect(() => {
    formik.setFieldValue(
      'influencers', 
      formik?.values?.project?.influencers.length > 0 ? formik?.values?.project?.influencers : [] 
    );
  }, [formik.values.project]);

  useEffect(() => {
    getActiveInfluencers();
    fetchLeads();
  }, []);

  flatpickr(".endDatePicker", {
    onClose: (selectedDates, dateStr) => {
      formik.setFieldValue('endDate', dateStr);
    },
    mode: "single",
    maxDate: new Date().fp_incr(365),
    locale: Portuguese,
    enableTime: true,
    plugins: [new confirmDatePlugin({})],
    disableMobile: true
  })

  flatpickr(".dueDatePicker", {
    onClose: (selectedDates, dateStr, fp) => {
      formik.setFieldValue('dueDate', dateStr);
    },
    mode: "single",
    maxDate: new Date().fp_incr(365),
    locale: Portuguese,
    enableTime: true,
    plugins: [new confirmDatePlugin({})],
    disableMobile: true
  })

  const actualTime = new Date().getTime();
  const timeComparison = moment(formik.values.dueDate, 'YYYY-MM-DD HH:mm').valueOf() - actualTime;

  useEffect(() => {
    if (formik?.values?.lead?.id === 'Criar nova empresa +') {
      setNewLeadOpen(true);
    };
    formik.setFieldValue('project', null);
  }, [formik.values.lead]);

  const handleNewLeadClose = () => {
    setNewLeadOpen(false);
    formik.setFieldValue('lead', null);
    leadFormik.resetForm();
  };

  useEffect(() => {
    if (!formik?.values?.project?.id) {
      formik.setFieldValue('tags', []);
    };
  }, [formik?.values?.project?.id]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const filter = createFilterOptions();

  const teamOptionsByRole = () => {
    if (role === 'MARKETING') {
      return userTeams;
    };
    return teams;
  };

  return (
    <>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <S.MainContainer container flexDirection='column' gap='10px'>
        <Formik
          initialValues={formik.initialValues}
          validationSchema={formik.validationSchema}
          onSubmit={formik.handleSubmit}
        >
          <Form>
            <Grid container justifyContent='space-between'>
              <S.TeamGrid container item xs={12} md={6} xl={6} flexDirection='column'>
                <p className="dueDate">Time</p>
                <Autocomplete
                  disablePortal
                  id='status'
                  value={formik.values?.team ? formik.values?.team : null}
                  onChange={(e, value) => {
                    formik.setFieldValue('team', value)
                  }}
                  options={selectedTeam?.tag ? [selectedTeam] : teamOptionsByRole()}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <S.Test {...params} placeholder='Time' />}
                  isOptionEqualToValue={(option, value) => option.tag === value.tag}
                  disableClearable
                />
                {(Boolean(formik.errors.team)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>
                  {formik.errors.team}
                </FormHelperText>}
              </S.TeamGrid>
              <Grid container item xs={12} md={6} xl={6} flexDirection='column' mb='10px'>
                <p className="dueDate">Data de Entrega</p>
                <S.DueDate className="dueDatePicker">
                  <p>
                    {
                      formik.values.dueDate
                        ?
                        moment(formik.values.dueDate).tz('America/Maceio').format('DD [de] MMM [às] HH:mm')
                        :
                        "-"
                    }
                  </p>
                  {timeComparison > 0 && timeComparison <= 86400000 &&
                    <S.DateAlertFlag>
                      <p>Entregar em breve</p>
                    </S.DateAlertFlag>
                  }
                  {timeComparison < 0 &&
                    <S.DateAlertExpireFlag>
                      <p>Em atraso</p>
                    </S.DateAlertExpireFlag>
                  }
                  <IoIosArrowDown size='12px' style={{ alignSelf: "center" }} />
                </S.DueDate>
              </Grid>
            </Grid>
            <S.CardDetails container>
              <Grid container item xs={12} md={6} lg={6} flexDirection='column'>
                <p>Membros</p>
                <Grid container mt='5px'>
                  {formik.values.members.map((member) => (
                    <LightTooltip title={member?.name} key={member?.id}>
                      <div>
                        <Avatar img={member?.avatar ?? placeholderImg} />
                      </div>
                    </LightTooltip>
                  ))
                  }
                  {role !== 'COMERCIAL' &&
                    <LightTooltip title='Adicionar membro'>
                      <div>
                        <Avatar onClick={() => setOpenMembersModal(true)}>
                          <IoAdd />
                        </Avatar>
                      </div>
                    </LightTooltip>
                  }
                </Grid>
                {(Boolean(formik.errors.members)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.members}</FormHelperText>}
              </Grid>
              <Grid container item xs={12} md={6} lg={6} flexDirection='column'>
                <p>Etiquetas</p>
                <Grid container mt='5px'>
                  {formik?.values?.tags.map((tag, index) => (
                    <S.Tag key={index} style={{ backgroundColor: tag?.bgcolor }}>{tag?.title}</S.Tag>
                  ))
                  }
                  <LightTooltip title='Adicionar etiqueta'>
                    <div>
                      <Avatar onClick={() => setOpenTagModal(true)}>
                        <IoAdd />
                      </Avatar>
                    </div>
                  </LightTooltip>
                </Grid>
              </Grid>
            </S.CardDetails>
            <S.Divider />
            <Grid container flexDirection='column' gap='20px' my='15px'>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    id='lead'
                    disablePortal
                    value={formik.values?.lead ? formik.values?.lead : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('lead', value)
                    }}
                    options={leadsArr ? leadsArr[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) => <S.Input {...params} placeholder='Empresa' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);

                      filtered.push({
                        businessName: "Criar nova empresa +", id: "Criar nova empresa +"
                      });

                      return filtered;
                    }}
                  />
                  {(Boolean(formik.errors.lead)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.lead}</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    id='project'
                    disablePortal
                    value={formik.values?.project ? formik.values?.project : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('project', value)
                    }}
                    options={(projects && projects[0]) ? projects[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => <S.Input {...params} placeholder='Projeto' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                  {(Boolean(formik.errors.project)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.project}</FormHelperText>}
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    hiddenLabel
                    id='title'
                    name='title'
                    type='text'
                    placeholder="Título"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    error={(Boolean(formik.errors.title))}
                    helperText={formik.errors.title}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    id="influencers"
                    name="influencers"
                    disablePortal
                    disableCloseOnSelect
                    multiple
                    readOnly
                    value={formik.values.influencers ? formik.values.influencers : []}
                    onChange={(e, value) => {
                      formik.setFieldValue('influencers', value)
                    }}
                    options={activeInfluencers ? activeInfluencers[0] : []}
                    sx={{ width: "100%" }}
                    renderInput={(params) => <S.Input {...params} placeholder='Influencers' />}
                    getOptionLabel={(option) => option.name}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    renderOption={(props, option, { selected }) => (
                      <li {...props}>
                        <Checkbox
                          icon={icon}
                          checkedIcon={checkedIcon}
                          style={{ marginRight: 8 }}
                          checked={selected}
                        />
                        {option.name}
                      </li>
                    )}
                  />
                  {(Boolean(formik.errors.influencers)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>{formik.errors.influencers}</FormHelperText>}
                </S.InputGrid>
              </Grid>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    multiline
                    hiddenLabel
                    id='description'
                    name='description'
                    type='text'
                    placeholder="Descrição"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.description}
                  />
                </S.InputGrid>
              </Grid>
            </Grid>
            <Grid container justifyContent='flex-end'>
              <ButtonCustom
                sx={{ maxWidth: '120px', height: '40px !important' }}
                type='submit'
                id='saveChanges'
                onClick={() => setValidateFields(true)}
              >
                Salvar
              </ButtonCustom>
            </Grid>
          </Form>
        </Formik>
      </S.MainContainer >
      <Modal size='sm' open={openTagModal} onClose={() => setOpenTagModal(false)}>
        <TagsModal
          cardTags={formik.values.tags}
          setTags={formik.setFieldValue}
          closeModal={() => setOpenTagModal(false)}
          projectId={formik?.values?.project?.id ?? ""}
          team={formik?.values?.team?.tag}
        />
      </Modal>
      <Modal size='sm' open={openMembersModal} onClose={() => setOpenMembersModal(false)}>
        <MembersModal
          cardMembers={formik.values.members}
          setMembers={formik.setFieldValue}
          closeModal={() => setOpenMembersModal(false)}
          team={formik?.values?.team?.tag}
        />
      </Modal>
      <Modal
        open={newLeadOpen}
        onClose={handleNewLeadClose}
        size={"sm"}
      >
        <Grid container flexDirection='column' gap='30px'>
          <p>Nova Empresa</p>
          <Formik initialValues={leadFormik.initialValues} validationSchema={leadFormik.validationSchema} onSubmit={leadFormik.handleSubmit}>
            <Form style={{ width: '100%' }}>
              <Grid container flexDirection='column' gap='15px'>
                <S.InputGrid item xl={12}>
                  <S.Input
                    fullWidth
                    name='businessName'
                    id='businessName'
                    placeholder='Nome da empresa'
                    onChange={leadFormik.handleChange}
                    value={leadFormik.values.businessName}
                    error={(Boolean(leadFormik.errors.businessName))}
                    helperText={leadFormik.errors.businessName}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='branch'
                    disablePortal
                    id='branch'
                    value={leadFormik.values.branch ? leadFormik.values.branch : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('branch')
                      leadFormik.setFieldValue('branch', value ? value : '')
                    }}
                    options={branches ? branches : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Ramo' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.branch) && leadFormik.touched.branch) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Ramo é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <Autocomplete
                    name='curve'
                    id='curve'
                    disablePortal
                    value={leadFormik.values.curve ? leadFormik.values.curve : null}
                    onChange={(e, value) => {
                      leadFormik.setFieldTouched('curve')
                      leadFormik.setFieldValue('curve', value ? value : '')
                    }}
                    options={curves ? curves : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => <S.Input {...params} placeholder='Curva' />}
                    isOptionEqualToValue={(option, value) => option === value}
                  />
                  {(Boolean(leadFormik.errors.curve) && leadFormik.touched.curve) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Curva é um campo obrigatório</FormHelperText>}
                </S.InputGrid>
                <S.InputGrid item xs={12} md={12} xl={12}>
                  <S.Input
                    fullWidth
                    name='businessDescription'
                    id='businessDescription'
                    placeholder='Descrição'
                    onChange={leadFormik.handleChange}
                    value={leadFormik.values.businessDescription}
                    error={(Boolean(leadFormik.errors.businessDescription))}
                    helperText={leadFormik.errors.businessDescription}
                  />
                </S.InputGrid>
                <Grid container justifyContent='flex-end'>
                  <ButtonCustom label='Salvar' type='submit' sx={{ width: "100px", height: "40px !important", fontSize: "18px", fontWeight: "500" }} />
                </Grid>
              </Grid>
            </Form>
          </Formik>
        </Grid>
      </Modal>
    </>
  )
}

export default CreateCardModal