import React from "react";
import { Grid, Checkbox } from "@mui/material";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import VuiBox from "components/VuiBox";
import { RiFacebookBoxFill } from "react-icons/ri";
import ze from "assets/ze_pointing.png";
import * as S from "./style";
import { REACT_APP_API_URL } from "utils/requests/auth";
import showToast from "components/Toast/Toast";
import * as T from "components/Toast/style";
import { Link } from "react-router-dom";
import { TokenContext } from "context/TokenContext";

const LinkModalContent = (props) => {
  const FACEBOOK_AUTH_URL = REACT_APP_API_URL + '/auth/facebook'
  const { decodedToken } = React.useContext(TokenContext);

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const openFacebookAuth = () => {
    window.location.href = FACEBOOK_AUTH_URL;
  };

/*   const facebook = () => {
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
    FB.init({
      appId: "589588973346726",
      xfbml: true,
      version: "v17.0",
    });
    FB.login(function (response) {
      console.log(response);
      if (response.authResponse) {
        console.log(response.authResponse);
        showToast("Conexão com o Facebook realizada com sucesso!", "success");
        setTimeout(() => props?.closeModal(), 3000);
        FB.api("/me", { fields: "name, email" }, function (response) {});
      } else {
        showToast("Falha na conexão com o Facebook", "error");
      }
    });
  }; */

  return (
    <S.MainGrid container>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <S.TextGrid container item xs={10} lg={7} gap="15px">
        <Grid>
          <p>Bem vindo</p>
          <p className="username">{decodedToken?.name ?? ""}</p>
          <p className="description">
            Para poder ter acesso a todas as vantagens da plataforma você precisa vincular suas
            contas ao nosso sistema e aceitar nossos Termos e Condições.
          </p>
        </Grid>
        <ButtonCustom
          label="Vincular Conta"
          disabled={!checked}
          onClick={() => openFacebookAuth()}
          startIcon={<RiFacebookBoxFill size="20px" />}
        />
        <Grid container>
          <Grid container gap="5px">
            <Checkbox checked={checked} onChange={handleChange} color="primary" />
            <p>Eu aceito os </p>
            <Link to="/termsandconditions" target="_blank" rel="noopener noreferrer">
              <p className="terms">Termos e Condições</p>
            </Link>
            <p>e</p>
            <Link to="/privacypolicy" target="_blank" rel="noopener noreferrer">
              <p className="terms">Política de Privacidade.</p>
            </Link>
          </Grid>
        </Grid>
      </S.TextGrid>
      <Grid item lg={4} display="flex" justifyContent="flex-end">
        <VuiBox
          sx={() => ({
            backgroundImage: `url(${ze})`,
            height: "100%",
            width: "100%",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPositionX: "center",
            transform: "rotateY(180deg)",
          })}
        ></VuiBox>
      </Grid>
    </S.MainGrid>
  );
};

export default LinkModalContent;
