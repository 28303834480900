import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Background = styled(Grid)`
  background: linear-gradient(127.09deg, rgba(0, 0, 0, 0.6) 19.41%, rgba(0, 0, 0, 0.6) 76.65%) !important;
  height: 100vh;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
`

export const Loader = styled.div`
  width: 50px;
  padding: 8px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #E22B00;
  --_m: 
    conic-gradient(#0000 10%,#000),
    linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
          mask: var(--_m);
  -webkit-mask-composite: source-out;
          mask-composite: subtract;
  animation: l3 1s infinite linear;
@keyframes l3 {to{transform: rotate(1turn)}}

  @media(min-width: 1440px) {
    margin-top: 20px;
  }

/* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`