import mockProducts from "./MockProducts/MockProducts";

export const getProducts = async (page, filters) => {
  const size = 10;
  const start = page * size;
  const end = start + size;

  const filteredProducts = mockProducts.filter((product) => {
    const matchesTags = filters?.productTags?.length
      ? filters.productTags.some((tag) => product.tags?.includes(tag))
      : true;
    const matchesName = filters?.name
      ? product.name.toLowerCase().includes(filters.name.toLowerCase())
      : true;
    return matchesTags && matchesName;
  });

  const data = filteredProducts.slice(start, end);
  return data;
};

export const getProductsTags = async () => {
  const uniqueCategories = [...new Set(mockProducts.map((product) => product.tags))];

  const data = uniqueCategories.map((tag) => ({ id: tag, name: tag }));
  return data;
};

export const deleteProduct = async (name) => {
  const productIndex = mockProducts.findIndex((product) => product.name === name);
  if (productIndex !== -1) {
    mockProducts.splice(productIndex, 1);
    return { success: true };
  }
};

export const editProduct = async (name, updates) => {
  const productIndex = mockProducts.findIndex((product) => product.name === name);

  if (productIndex === -1) {
    return { message: "Produto não encontrado" };
  }

  mockProducts[productIndex] = {
    ...mockProducts[productIndex],
    ...updates,
  };

  return { success: true };
};

export const createProduct = async (productInfo) => {
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + ``, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(productInfo),
  });
  const data = await response.json();
  return data;
};

export const editProductPhoto = async (edit) => {
  const formData = new FormData();
  Object.entries(edit).forEach(entry => {
    const isArray = Array.isArray(entry[1]);
    if (isArray) {
      entry[1].forEach((str, index) => {
        formData.append(`${entry[0]}[${index}]`, str);
      })
      return
    }
    formData.append(entry[0], entry[1]);
  });
  const token = localStorage.getItem("token");
  const response = await fetch(REACT_APP_API_URL + ``, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: formData,
  });
  const data = await response.json();
  return data;
};