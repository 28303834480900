import React from 'react'
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid } from '@mui/material';
import * as S from "./style"
import zeMovie from 'assets/ze_movie.png'
import underConstruction from 'assets/construction.png'
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import { Link } from 'react-router-dom';

const Construction = ({ page }) => {
  const url = {
    nft: 'https://metaverso.viuzz.com.br/',
    tv: 'https://tv.viuzz.com.br/',
    ecommerce: 'https://front-ecommerce-9dcb3.web.app/'
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <S.MainGrid container item sm={12} md={12} lg={12} xl={12}>
        <Grid container>
          <S.ZeGrid container item xs={7} sm={6} md={6} xl={6} justifyContent='center'>
            <img src={zeMovie} draggable='false' />
          </S.ZeGrid>
          <S.ImageGrid item xs={5} sm={6} md={6} xl={6} justifyContent='flex-start'>
            <img src={underConstruction} draggable='false' />
          </S.ImageGrid>
        </Grid>
        <Grid container justifyContent='center'>
          <Link to={url[page]} target='_blank'>
            <ButtonCustom
              label='Dar uma olhada 👀'
              sx={{ width: '250px', fontSize: "16px", marginBottom: '30px', marginTop: '20px' }}
            />
          </Link>
        </Grid>
      </S.MainGrid>
    </DashboardLayout>
  )
}

export default Construction