import { Card, Grid } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import newspaper from 'assets/icons/newspaper.svg';
import refresh from 'assets/icons/refresh.svg';
import placeholderImg from 'assets/placeholder.png';
import Modal from "components/Modal/Modal";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import TimelineItem from "defaultComponents/Timeline/TimelineItem";
import Transactions from "layouts/billing/components/Transactions";
import IndividualProjects from "layouts/dashboard/components/IndividualProjects";
import moment from 'moment';
import 'moment/locale/pt-br';
import React from 'react';
import { IoEllipse } from 'react-icons/io5';
import { RiFundsLine } from "react-icons/ri";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getNewsFeed } from 'utils/requests/other';
import eye from '../../../assets/icons/eye.svg';
import pencil from '../../../assets/icons/pencil.png';
import instagram from '../../../assets/icons/socials/instagrambig.png';
import tiktok from '../../../assets/icons/socials/tiktokbig.png';
import twitter from '../../../assets/icons/socials/twitterbig.png';
import youtube from '../../../assets/icons/socials/youtubebig.png';
import userAdd from '../../../assets/icons/user-add.svg';
import userVoice from '../../../assets/icons/user-voice.svg';
import EditInfluencer from './ModalContent/EditInfluencer';
import * as S from './style';
import { TokenContext } from "context/TokenContext";
import EventDetails from 'pages/Calendar/ModalContent/EventDetails';
import * as T from "components/Toast/style"
import { abbreviateNumbers } from 'utils/utils';
import { getUser } from 'utils/requests/auth';
import PublicationDetails from 'pages/Calendar/ModalContent/PublicationDetails';
import LinkModalContent from 'layouts/dashboard/components/LinkModalContent/LinkModalContent';

const Influencer = (props) => {
  moment.locale("pt-br")
  const mobile = useMediaQuery("(max-width: 992px)");
  const navigate = useNavigate();
  const params = useParams();
  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [news, setNews] = React.useState([]);
  const [userInfo, setUserInfo] = React.useState();
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [isSearchingNews, setIsSearchingNews] = React.useState(true);
  const [openEventDetails, setOpenEventDetails] = React.useState(false);
  const [openPublicationDetails, setOpenPublicationDetails] = React.useState(false);
  const [eventToShow, setEventToShow] = React.useState();
  const [openFacebookModal, setOpenFacebookModal] = React.useState(false);

  const fetchNews = async (propUsername) => {
    const data = await getNewsFeed(propUsername ? propUsername : userInfo?.user?.username);
    if (!data.message) {
      const firstNews = data?.results?.slice(0, 3);
      setNews(firstNews);
      setIsSearchingNews(false);
    } else {
      setIsSearchingNews(false);
    }
  };

  const username = props.influencerUsername ? props.influencerUsername : params.influencer;

  const fetchUserInfo = async (propUsername) => {
    const getUsername = () => {
      if (propUsername) {
        return propUsername;
      };
      if (username) {
        return username;
      };
      return ''
    }
    const data = await getUser(getUsername());
    if (!data.statusCode) {
      setUserInfo(data);
    }
  };

  React.useEffect(() => {
    fetchUserInfo();
  }, []);

  React.useEffect(() => {
    if (userInfo) fetchNews();
    if ((!userInfo?.hasMetaToken) && (userInfo?.user?.username === decodedToken?.username)) {
      setOpenFacebookModal(true);
    };
  }, [userInfo]);

  const redirects = {
    ADMINISTRATOR: {
      metrics: `/influencers/${username}/metricas`
    },
    H_COMERCIAL: {
      metrics: `/influencers/${username}/metricas`
    },
    COMERCIAL: {
      metrics: `/influencers/${username}/metricas`
    },
    H_MARKETING: {
      metrics: `/influencers/${username}/metricas`
    },
    MARKETING: {
      metrics: `/influencers/${username}/metricas`
    },
    INFLUENCER: {
      metrics: '/metricas'
    }
  };

  const refetchAfterEditSave = (username) => {
    fetchUserInfo(username);
    setNews([]);
    setIsSearchingNews(true);
    fetchNews(username);
  };

  const handleOpenCalendarDetails = (event) => {
    if(event?.extendedProps?.type === "PUBLICATION") {
      setOpenPublicationDetails(true);
      return
    }
    setOpenEventDetails(true);
  };

  return (
    <DashboardLayout>
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <DashboardNavbar customTitle={userInfo?.user?.name} />
      <Grid container spacing='18px'>
        <Grid item xs={12} lg={12} xl={8}>
          <Card>
            {mobile &&
              <Grid container justifyContent='flex-end' gap='10px'>
                <S.MetricsButton onClick={() => navigate(redirects[role]?.metrics)}>
                  <p>Métricas</p>
                  <RiFundsLine size='22px' />
                </S.MetricsButton>
                {((role === 'ADMINISTRATOR') || (role === 'INFLUENCER')) &&
                  <S.EditButtonGrid>
                    <S.EditButton onClick={() => setOpenEditModal(true)}>
                      <img src={pencil} />
                    </S.EditButton>
                  </S.EditButtonGrid>
                }
              </Grid>
            }
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <Grid container gap='20px'>
                <Grid container item lg={1}>
                  <S.InfluencerPhoto>
                    <div className='photoContainer'>
                      <img src={userInfo?.user?.avatar ? userInfo?.user?.avatar : placeholderImg} draggable='false' />
                    </div>
                  </S.InfluencerPhoto>
                </Grid>
                <S.InfluencerTextGrid container flexDirection='column' item lg={10}>
                  <Grid container>
                    <Grid item xs={12} md={6} xl={6}>
                      <p className='name'>
                        {userInfo?.user?.name ?? ''}
                      </p>
                    </Grid>
                    {!mobile &&
                      <Grid container justifyContent='flex-end' gap='10px' item xs={12} md={6} xl={6}>
                        <S.MetricsButton onClick={() => navigate(redirects[role].metrics)}>
                          <p>Métricas</p>
                          <RiFundsLine size='22px' />
                        </S.MetricsButton>
                        {((role === 'ADMINISTRATOR') || (role === 'INFLUENCER')) &&
                          <S.EditButtonGrid>
                            <S.EditButton onClick={() => setOpenEditModal(true)}>
                              <img src={pencil} />
                            </S.EditButton>
                          </S.EditButtonGrid>
                        }
                      </Grid>
                    }
                  </Grid>
                  <p className='subName'>
                    @{userInfo?.user?.username ?? ''}
                  </p>
                  <p className='description'>
                    {userInfo?.user?.bio ?? ''}
                  </p>
                </S.InfluencerTextGrid>
              </Grid>
            </VuiBox>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <Grid container gap='20px' justifyContent='space-evenly'>
                <S.CardGrid container item lg={2.5} flexDirection='column'>
                  <img src={instagram} className='logo' />
                  <Grid container justifyContent='center'>
                    <p className='postsNumber'>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia?.Instagram?.postsTotal ?? 0, 2)}</p>
                    <p className='posts'>Posts</p>
                  </Grid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Seguidores</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia?.Instagram?.followersTotal
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia?.Instagram.followersTotal ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userAdd} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Impressões</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia?.Instagram?.impressionsTotal
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia?.Instagram?.impressionsTotal ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={eye} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Alcance</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia?.Instagram?.reachTotal
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia?.Instagram?.reachTotal ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userVoice} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                </S.CardGrid>
                <S.CardGrid container item lg={2.5} flexDirection='column'>
                  <img src={tiktok} className='logo' />
                  <Grid container justifyContent='center'>
                    <p className='postsNumber'>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.tiktok?.posts ?? 0, 2)}</p>
                    <p className='posts'>Posts</p>
                  </Grid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Seguidores</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.tiktok?.followers
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.tiktok?.followers ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userAdd} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Impressões</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.tiktok?.reactions
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.tiktok?.reactions ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={eye} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Alcance</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.tiktok?.reach
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.tiktok?.reach ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userVoice} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                </S.CardGrid>
                <S.CardGrid container item lg={2.5} flexDirection='column'>
                  <img src={twitter} className='logo' />
                  <Grid container justifyContent='center'>
                    <p className='postsNumber'>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.twitter?.posts ?? 0, 2)}</p>
                    <p className='posts'>Posts</p>
                  </Grid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Seguidores</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.twitter?.followers
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.twitter?.followers ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userAdd} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Impressões</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.twitter?.reactions
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.twitter?.reactions ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={eye} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Alcance</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.twitter?.reach
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.twitter?.reach ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userVoice} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                </S.CardGrid>
                <S.CardGrid container item lg={2.5} flexDirection='column'>
                  <img src={youtube} className='logo' />
                  <Grid container justifyContent='center'>
                    <p className='postsNumber'>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.youtube?.posts ?? 0, 2)}</p>
                    <p className='posts'>Posts</p>
                  </Grid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Seguidores</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.youtube?.followers
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.youtube?.followers ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userAdd} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Impressões</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.youtube?.reactions
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.youtube?.reactions ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={eye} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                  <S.InnerCardGrid container>
                    <Grid container>
                      <p className='title'>Alcance</p>
                      <Grid container>
                        {userInfo?.returnedvalues?.socialMedia[0]?.youtube?.reach
                          ?
                          <>
                            <p>{abbreviateNumbers(userInfo?.returnedvalues?.socialMedia[0]?.youtube?.reach ?? 0, 2)}</p>
                            <p className='percentage'>+0%</p>
                          </>
                          :
                          <p>-</p>
                        }
                      </Grid>
                    </Grid>
                    <S.InnerCardButton disabled>
                      <img src={userVoice} width='20px' height='20px' />
                    </S.InnerCardButton>
                  </S.InnerCardGrid>
                </S.CardGrid>
              </Grid>
            </VuiBox>
          </Card>
        </Grid>
        <Grid item xs={12} lg={6} xl={4}>
          <S.ScheduleList title="Agenda">
            {userInfo?.returnedvalues?.calendar.length === 0 && <p style={{ color: "#fff" }}>Sem informações</p>}
            {userInfo?.returnedvalues?.calendar.map((event, index) => (
              <Grid key={index} onClick={() => { setEventToShow(event); handleOpenCalendarDetails(event); }}>
                <TimelineItem
                  key={event?.id}
                  color="viuzz"
                  icon={<IoEllipse />}
                  title={event?.title}
                  dateTimeStart={event?.start ? (moment(event?.start).format("D MMM HH:mm").toUpperCase()) : ''}
                  dateTimeEnd={event?.end ? (moment(event?.end).format("D MMM HH:mm").toUpperCase()) : ''}
                  badges={event?.extendedProps?.usersRelated?.map(category => category.name)}
                  location={event?.extendedProps?.place}
                />
              </Grid>
            ))}
          </S.ScheduleList>
        </Grid>
        <Grid item xs={12} md={12} lg={6}>
          <IndividualProjects
            projects={userInfo?.returnedvalues?.projects ? userInfo?.returnedvalues?.projects : false}
            showFinished
            routing={userInfo?.user?.username}
          />
        </Grid>
        <Grid item xs={12} md={12} lg={12} xl={6}>
          <Card sx={{ padding: "30px 40px" }}>
            <Grid>
              <S.UpdateButtonGrid>
                <S.EditButton>
                  <img src={refresh} onClick={() => { setNews([]); setIsSearchingNews(true); fetchNews(); }} />
                </S.EditButton>
              </S.UpdateButtonGrid>
              <VuiTypography variant="lg" mb="6px" gutterBottom color='white'>
                Últimas Notícias
              </VuiTypography>
              <S.HashtagGrid container>
                {userInfo?.user?.searchTerms?.map((term) => (
                  <p>#{term}</p>
                ))
                }
              </S.HashtagGrid>
            </Grid>
            <S.NewsContainer container justifyContent='space-evenly'>
              {!userInfo?.returnedvalues?.searchTerms &&
                <Grid container justifyContent='center'>
                  <p>Nenhum termo de busca para este usuário</p>
                </Grid>
              }
              {news?.length === 0 && isSearchingNews &&
                <Grid container justifyContent='center'>
                  <S.Loader />
                </Grid>
              }
              {news?.length === 0 && !isSearchingNews && userInfo?.returnedvalues?.searchTerms?.length > 0 &&
                <Grid container justifyContent='center'>
                  <p>Sem notícias para exibir</p>
                </Grid>
              }
              {/* <p>Em manutenção 🚧</p> */}
              {news?.map((news) => (
                <Grid item lg={3} key={news.link}>
                  <Link to={news.link} target='_blank' rel="noopener noreferrer">
                    <S.NewsGrid item>
                      {/* <img src={news.img} loading='lazy' className='photo' /> */}
                      <Grid container gap='5px'>
                        <img src={newspaper} className='newsLogo' />
                        <p>{news.media.length > 12 ? news.media.substring(0, 12) + "..." : news.media}</p>
                      </Grid>
                      <p className='text'>{news.desc ? news.desc : news.title}</p>
                      <p className='date'>{news.date}</p>
                    </S.NewsGrid>
                  </Link>
                </Grid>
              ))}
            </S.NewsContainer>
          </Card>
        </Grid>
        {(role === 'ADMINISTRATOR' || role === 'INFLUENCER' || role === 'H_COMERCIAL') &&
          <Grid item xs={12} md={12} xl={12}>
            <Transactions
              data={userInfo?.returnedvalues?.financial}
              influencerInfo={userInfo?.user}
              hideRedirect={!(role === 'ADMINISTRATOR' || role === 'INFLUENCER')}
            />
          </Grid>
        }
      </Grid>
      <Modal open={openEditModal} onClose={() => setOpenEditModal(false)}>
        <EditInfluencer
          userInfo={userInfo?.user ?? false}
          refetch={refetchAfterEditSave}
          closeModal={() => setOpenEditModal(false)}
          returnedValues={userInfo?.returnedvalues ?? false}
        />
      </Modal>
      <Modal open={openEventDetails} onClose={() => { setOpenEventDetails(false); setEventToShow(); }}>
        <EventDetails event={eventToShow} />
      </Modal>
      <Modal open={openPublicationDetails} onClose={() => { setOpenPublicationDetails(false); setEventToShow(); }}>
        <PublicationDetails event={eventToShow} />
      </Modal>
      <Modal open={openFacebookModal} onClose={() => setOpenFacebookModal(false)} contentProps={{ paddingBottom: '0', paddingRight: '0', paddingLeft: '0' }}>
        <LinkModalContent closeModal={() => setOpenFacebookModal(false)} />
      </Modal>
    </DashboardLayout >
  )
}

export default Influencer