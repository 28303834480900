const mockProducts = [
  {
    id: 1,
    name: "Moletom Gabô Pantaleão",
    model: "Gabô Hoodie",
    tags: "Moletons",
    price: 199.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 2,
    name: "Camiseta Alvxaro",
    model: "Alvxaro Tee",
    tags: "Camisetas",
    price: 99.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 3,
    name: "FunkoPop Hugo Novaes",
    model: "Hugo Novaes Funko",
    tags: "FunkoPop",
    price: 149.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 4,
    name: "Caneca Maceió Ordinário",
    model: "Maceió Mug",
    tags: "Canecas",
    price: 39.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 5,
    name: "Camiseta Mano Walter",
    model: "Mano Walter Tee",
    tags: "Camisetas",
    price: 99.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 6,
    name: "Chaveiro Leuriscleia",
    model: "Leuriscleia Keychain",
    tags: "Chaveiros",
    price: 29.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 7,
    name: "Caneca Gabô Pantaleão",
    model: "Gabô Mug",
    tags: "Canecas",
    price: 39.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 8,
    name: "Moletom Alvxaro",
    model: "Alvxaro Hoodie",
    tags: "Moletons",
    price: 199.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 9,
    name: "Chaveiro Maceió Ordinário",
    model: "Maceió Keychain",
    tags: "Chaveiros",
    price: 29.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 10,
    name: "FunkoPop Leuriscleia",
    model: "Leuriscleia Funko",
    tags: "FunkoPop",
    price: 149.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 11,
    name: "Camiseta Gabô Pantaleão",
    model: "Gabô Tee",
    tags: "Camisetas",
    price: 79.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 12,
    name: "Moletom Maceió Ordinário",
    model: "Maceió Hoodie",
    tags: "Moletons",
    price: 179.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 13,
    name: "Caneca Hugo Novaes",
    model: "Hugo Mug",
    tags: "Canecas",
    price: 59.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 14,
    name: "FunkoPop Mano Walter",
    model: "Mano Walter Funko",
    tags: "FunkoPop",
    price: 129.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 15,
    name: "Chaveiro Alvxaro",
    model: "Alvxaro Keychain",
    tags: "Chaveiros",
    price: 49.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 16,
    name: "Camiseta Leuriscleia",
    model: "Leuriscleia Tee",
    tags: "Camisetas",
    price: 79.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 17,
    name: "Moletom Hugo Novaes",
    model: "Hugo Hoodie",
    tags: "Moletons",
    price: 179.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 18,
    name: "Caneca Alvxaro",
    model: "Alvxaro Mug",
    tags: "Canecas",
    price: 59.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 19,
    name: "FunkoPop Maceió Ordinário",
    model: "Maceió Funko",
    tags: "FunkoPop",
    price: 169.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 20,
    name: "Chaveiro Gabô Pantaleão",
    model: "Gabô Keychain",
    tags: "Chaveiros",
    price: 39.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 21,
    name: "Moletom Leuriscleia",
    model: "Leuriscleia Hoodie",
    tags: "Moletons",
    price: 189.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 22,
    name: "Camiseta Mano Walter",
    model: "Mano Walter Tee",
    tags: "Camisetas",
    price: 89.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 23,
    name: "FunkoPop Alvxaro",
    model: "Alvxaro Funko",
    tags: "FunkoPop",
    price: 159.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 24,
    name: "Caneca Mano Walter",
    model: "Mano Mug",
    tags: "Canecas",
    price: 49.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 25,
    name: "Camiseta Gabô Pantaleão",
    model: "Gabô Tee",
    tags: "Camisetas",
    price: 89.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 26,
    name: "Chaveiro Maceió Ordinário",
    model: "Maceió Keychain",
    tags: "Chaveiros",
    price: 39.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 27,
    name: "Caneca Leuriscleia",
    model: "Leuriscleia Mug",
    tags: "Canecas",
    price: 49.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 28,
    name: "Moletom Maceió Ordinário",
    model: "Maceió Hoodie",
    tags: "Moletons",
    price: 189.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 29,
    name: "Chaveiro Alvxaro",
    model: "Alvxaro Keychain",
    tags: "Chaveiros",
    price: 49.99,
    stock: "Pré-Venda",
    active: false,
    image: "https://via.placeholder.com/150",
  },
  {
    id: 30,
    name: "FunkoPop Hugo Novaes",
    model: "Hugo Novaes Funko",
    tags: "FunkoPop",
    price: 159.99,
    stock: "Pré-Venda",
    active: true,
    image: "https://via.placeholder.com/150",
  },
];

export default mockProducts
