import { useState, useEffect } from "react";
import * as S from "../style";
import { Grid } from "@mui/material";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { RiAddFill, RiArrowLeftLine, RiArrowRightLine, RiCalendarLine } from "react-icons/ri";
import { GoDotFill } from "react-icons/go";
import {
  IoCheckmarkCircle,
  IoCheckmarkOutline,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
} from "react-icons/io5";
import Modal from "components/Modal/Modal";
import showToast from "components/Toast/Toast";
import {
  editProjectCreative,
  rejectProjectCreative,
  approveProjectCreative,
  getProjectCreativeRejections,
} from "utils/requests/project";
import moment from "moment";
import "moment/locale/pt-br";
import { capitalize } from "utils/utils";
import { Link } from "react-router-dom";
import zePlaceholder from "assets/ze.png";
import VuiAvatar from "components/VuiAvatar";
import { getCreativeByInfluencer } from "utils/requests/project";
import Tabs from "@mui/material/Tabs";
import { createInfluencerCreative } from "utils/requests/project";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="sm"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      cursor: "pointer",
      position: "relative",
      "&:hover, &:focus": {
        zIndex: "10",
      },
    })}
  >
    {children}
  </VuiAvatar>
);

const CreativeForm = ({ project, projectId }) => {
  const isCampaign = project?.type === "CAMPANHA";

  const [creativeArr, setCreativeArr] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [openRejectLogModal, setOpenRejectLogModal] = useState(false);
  const [openStepDetailsModal, setOpenStepDetailsModal] = useState(false);
  const [stepDetailsNumber, setStepDetailsNumber] = useState();
  const [rejectedMessage, setRejectedMessage] = useState("");
  const [rejectionLog, setRejectionLog] = useState([]);
  const [activeInfluencer, setActiveInfluencer] = useState(
    isCampaign ? undefined : project?.influencers[0]
  );
  const [formStep, setFormStep] = useState(isCampaign ? 0 : 1);
  const [tabValue, setTabValue] = useState(0);
  const [openNewCreativeModal, setOpenNewCreativeModal] = useState(false);
  const [newCreativeTitle, setNewCreativeTitle] = useState("");

  const selectedCreative = creativeArr[tabValue]?.steps;

  const InfluencerSelection = () => {
    return (
      <Grid container flexDirection="column">
        <Grid container justifyContent="space-between">
          <S.FormTitleGrid container flexDirection="column" item sm={12} md={12} lg={5} xl={5}>
            <p>Criativos</p>
            <p className="subTitle">Selecione o Influencer</p>
          </S.FormTitleGrid>
          <S.BadgeContainer display="flex" alignItems="flex-end" item sm={12} md={12} lg={7} xl={7}>
            {project?.status === "ENDED" && (
              <S.FinishedProjectBadgeGrid item>
                <p>Projeto finalizado</p>
              </S.FinishedProjectBadgeGrid>
            )}
            <S.BadgeGrid item>
              <p>{capitalize(project?.type.toLowerCase())}</p>
            </S.BadgeGrid>
          </S.BadgeContainer>
        </Grid>
        <Grid container flexDirection="column" mt="50px">
          <Grid container>
            {project?.influencers.map((influencer) => (
              <Grid
                container
                item
                flexDirection="column"
                xs={6}
                md={4}
                lg={3}
                xl={2}
                key={influencer?.username}
              >
                <S.UsersPipelineGrid
                  container
                  item
                  flexDirection="column"
                  onClick={() => setActiveInfluencer(influencer)}
                >
                  <Avatar
                    size="xxl"
                    img={influencer?.avatar ? influencer?.avatar : zePlaceholder}
                  />
                  <p>{influencer?.name}</p>
                </S.UsersPipelineGrid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const verifyStep = () => {
    const lastStep = selectedCreative && selectedCreative[0];
    if (lastStep?.stepName === "FINAL_FILES") {
      return setActiveStep(5);
    }
    if (creativeArr[tabValue]?.status === "ACCEPTED") {
      return setActiveStep(4);
    }
    if (lastStep?.stepName === "PRODUCTION_FILES" || creativeArr[tabValue]?.status === "REJECTED") {
      return setActiveStep(3);
    }
    if (lastStep?.stepName === "PROJECT_DEFINITION") {
      return setActiveStep(2);
    }
    if (lastStep?.stepName === "DESCRIPTION") {
      return setActiveStep(1);
    }
    return setActiveStep(0);
  };

  const creativeSteps = {
    0: "DESCRIPTION",
    1: "PROJECT_DEFINITION",
    2: "PRODUCTION_FILES",
    /*     3: "DETAILS", */
    4: "FINAL_FILES",
  };

  const findContent = (stepName) => {
    const contentArr = selectedCreative?.map((step) => {
      if (step?.stepName === stepName) {
        return step?.content;
      }
    });
    if (contentArr?.length === 0) return "";
    const filteredContentArr = contentArr?.filter((content) => content !== undefined);
    return filteredContentArr?.length > 0 ? filteredContentArr[0] : "";
  };

  const fetchInfluencerCreative = async () => {
    const data = await getCreativeByInfluencer(projectId, activeInfluencer?.username);
    if (!data.message) {
      setCreativeArr(data);
      if (data?.length === 0) {
        showToast("Não foi possível acessar criativo deste influencer, contate o suporte", "error");
        return;
      }
      if (data?.length > 0 && formStep !== 1) {
        setFormStep(1);
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      details: selectedCreative?.details ?? "",
      description: findContent("DESCRIPTION") ?? "",
      projectDefinition: findContent("PROJECT_DEFINITION") ?? "",
      productionFiles: findContent("PRODUCTION_FILES") ?? "",
      finalFiles: findContent("FINAL_FILES") ?? "",
    },
    validationSchema: Yup.object({
      details: Yup.string().notRequired(),
      description: Yup.string().when("activeStep", {
        is: (value) => activeStep === 0,
        then: () => Yup.string().required("Campo não pode ser vazio"),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
      projectDefinition: Yup.string().when("activeStep", {
        is: (value) => activeStep === 1,
        then: () => Yup.string().required("Campo não pode ser vazio"),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
      productionFiles: Yup.string().when("activeStep", {
        is: (value) => activeStep === 2,
        then: () => Yup.string().required("Campo não pode ser vazio"),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
      finalFiles: Yup.string().when("activeStep", {
        is: (value) => activeStep === 4,
        then: () => Yup.string().required("Campo não pode ser vazio"),
        otherwise: () => Yup.string().notRequired().nullable(),
      }),
    }),
    onSubmit: async (values) => {
      const startCreativeValues = formik.initialValues;
      const creativeToEdit = {
        details: values.details !== startCreativeValues.details ? values.details : null,
        description:
          values.description !== startCreativeValues.description ? values.description : null,
        projectDefinition:
          values.projectDefinition !== startCreativeValues.projectDefinition
            ? values.projectDefinition
            : null,
        productionFiles:
          values.productionFiles !== startCreativeValues.productionFiles
            ? values.productionFiles
            : null,
        finalFiles: values.finalFiles !== startCreativeValues.finalFiles ? values.finalFiles : null,
      };
      Object.keys(creativeToEdit).forEach((key) => {
        if (creativeToEdit[key] === null) {
          delete creativeToEdit[key];
        }
      });
      const valueToSend = Object.entries(creativeToEdit);
      const mountedObj = {
        content: valueToSend ? valueToSend[0][1] : null,
        stepName: creativeSteps[activeStep],
      };
      if (Object.keys(creativeToEdit).length > 0)
        await editProjectCreative(creativeArr[tabValue]?.id, mountedObj).then((res) => {
          if (res.message) {
            showToast("Falha na edição do criativo", "error");
          }
          if (!res.message) {
            showToast("Criativo editado com sucesso!", "success");
            fetchInfluencerCreative();
            setActiveStep(activeStep + 1);
          }
        });
      if (Object.keys(creativeToEdit).length === 0)
        showToast("Sem dados alterados, campos sem alterações", "warning");
    },
  });

  const approvedSteps = [
    ["Briefing", "Descrição"],
    ["Construção do Projeto", "Definição do Processo"],
    ["Produção do Material", "Arquivos de Produção"],
    ["Revisão", "Detalhes a Revisar"],
    ["Entrega", "Arquivo Final"],
  ];

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    formik.resetForm();
  };

  useEffect(() => {
    verifyStep();
  }, [selectedCreative]);

  const CustomStepIcon = (props) => {
    return (
      <>
        {props.completed && <IoCheckmarkCircle size="24px" color="#FB8404" />}
        {!props.completed && <S.StyledStep className={props.active ? "stepActive" : null} />}
      </>
    );
  };

  const handleReject = async () => {
    await rejectProjectCreative(creativeArr[tabValue]?.id, {
      rejectedReason: rejectedMessage,
    }).then((res) => {
      if (res.message) {
        showToast("Falha ao rejeitar criativo", "error");
      }
      if (!res.message) {
        showToast("Criativo rejeitado com sucesso!", "success");
        setRejectedMessage("");
        fetchInfluencerCreative();
      }
    });
  };

  const handleApproval = async () => {
    await approveProjectCreative(creativeArr[tabValue]?.id).then((res) => {
      if (res.message) {
        showToast("Falha ao aprovar criativo", "error");
      }
      if (!res.message) {
        showToast("Criativo aprovado com sucesso!", "success");
        fetchInfluencerCreative();
      }
    });
  };

  const fetchRejectionLog = async () => {
    const data = await getProjectCreativeRejections(projectId);
    if (!data.message) {
      setRejectionLog(data);
    }
  };

  useEffect(() => {
    activeInfluencer && fetchInfluencerCreative();
  }, [activeInfluencer]);

  useEffect(() => {
    if (openRejectLogModal) {
      fetchRejectionLog();
    }
  }, [openRejectLogModal]);

  useEffect(() => {
    verifyStep();
  }, [creativeArr]);

  const handleStepDetailsModal = (step) => {
    setStepDetailsNumber(step);
    setOpenStepDetailsModal(true);
  };

  const filterRejectedSteps = (rejectedSteps) => {
    const stepsArr = rejectedSteps?.map((step) => {
      if (step?.stepName === "PRODUCTION_FILES") {
        return step?.content;
      }
      return "";
    });
    const filteredStepsArr = stepsArr?.filter((step) => step !== "");
    return filteredStepsArr;
  };

  const isValidUrl = (str) => {
    const pattern = new RegExp(
      "^(?:(?:(?:https?|ftp):)?\\/\\/)?" +
        "(?:\\S+(?::\\S*)?@)?" +
        "(?:" +
        "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
        "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
        "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
        "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
        "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
        "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
        "|" +
        "(?:" +
        "(?:" +
        "[a-zA-Z0-9\\u00a1-\\uffff]" +
        "[a-zA-Z0-9\\u00a1-\\uffff_-]{0,62}" +
        ")?" +
        "[a-zA-Z0-9\\u00a1-\\uffff]\\." +
        ")+" +
        "(?:[a-zA-Z\\u00a1-\\uffff]{2,}\\.?)" +
        ")" +
        "(?::\\d{2,5})?" +
        "(?:[/?#][\\w\\-._~:/?#[\\]@!$&'()*+,;=.%]*)?$",
      "i"
    );
    return pattern.test(str);
  };

  const hasHttpProtocol = (url) => {
    const pattern = /^https?:\/\//;
    return pattern.test(url);
  };

  const LinkInput = ({ str, children }) => {
    if (isValidUrl(str)) {
      return (
        <Link to={hasHttpProtocol(str) ? str : `https://${str}`} target="_blank" key={str}>
          {children}
        </Link>
      );
    }
    return children;
  };

  const verifyInputClassname = (step, value) => {
    if (activeStep !== step && isValidUrl(value)) {
      return "readOnly urlPointer";
    }
    if (activeStep !== step) {
      return "readOnly";
    }
    return "";
  };

  const createNewCreative = async () => {
    if(!newCreativeTitle) {
      return showToast("Título do criativo não pode ser vazio!", "error");
    };
    await createInfluencerCreative({
      project: projectId,
      influencer: activeInfluencer?.id,
      title: newCreativeTitle
    }).then((res) => {
      if (res.id) {
        fetchInfluencerCreative();
        setTabValue(creativeArr.length);
        setNewCreativeTitle('');
        setOpenNewCreativeModal(false);
      } else {
        showToast("Falha ao criar criativo", "error");
      }
    });
  };

  const reviewPlaceholder = {
    ACCEPTED: "Aprovado",
    REJECTED: "Recusado",
    "AWAITING REVIEW": "Aprovar ou recusar a produção do material?",
  };

  return (
    <>
      <S.ProjectCard>
        {isCampaign && formStep === 0 ? (
          <InfluencerSelection />
        ) : (
          <>
            <Grid container justifyContent="space-between">
              <S.FormTitleGrid container flexDirection="column" item sm={12} md={12} lg={5} xl={5}>
                <p>Criativos</p>
                <p className="subTitle">Processo criativo do projeto</p>
              </S.FormTitleGrid>
              <S.BadgeContainer
                display="flex"
                alignItems="flex-end"
                item
                sm={12}
                md={12}
                lg={7}
                xl={7}
              >
                <S.RejectedBadgeGrid item onClick={() => setOpenRejectLogModal(true)}>
                  <p>Acessar Recusas</p>
                </S.RejectedBadgeGrid>
                {project?.status === "ENDED" && (
                  <S.FinishedProjectBadgeGrid item>
                    <p>Projeto finalizado</p>
                  </S.FinishedProjectBadgeGrid>
                )}
                <S.BadgeGrid item>
                  <p>{capitalize(project?.type.toLowerCase())}</p>
                </S.BadgeGrid>
              </S.BadgeContainer>
            </Grid>
            {isCampaign && (
              <S.PreviousPageGrid
                container
                gap="10px"
                onClick={() => {
                  setFormStep(0);
                  setActiveInfluencer(undefined);
                }}
              >
                <RiArrowLeftLine size="16px" style={{ alignSelf: "center" }} />
                <p>Voltar</p>
              </S.PreviousPageGrid>
            )}
            <Grid container mt="15px">
              <Grid item md={12} lg={11} xl={11.5}>
                <Tabs
                  orientation="horizontal"
                  value={tabValue}
                  onChange={handleSetTabValue}
                  variant="scrollable"
                  scrollButtons="auto"
                  sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
                  TabIndicatorProps={{
                    style: {
                      borderRadius: "5px",
                    },
                  }}
                >
                  {creativeArr.map((creative, index) => (
                    <S.ListFilterTab
                      label={creative.title}
                      value={index}
                      key={index}
                    />
                  ))}
                </Tabs>
              </Grid>
              <Grid container item md={12} lg={1} xl={0.5} justifyContent="end">
                <S.AddCreativeButton onClick={() => setOpenNewCreativeModal(true)}>
                  <RiAddFill size="25px" color="#fff" />
                </S.AddCreativeButton>
              </Grid>
            </Grid>
            <Formik
              initialValues={formik.initialValues}
              validationSchema={formik.validationSchema}
              onSubmit={formik.handleSubmit}
            >
              <Form>
                <S.FormGrid container>
                  <S.LeftBorderGrid item md={5}>
                    <Stepper /* nonlinear='true' */ activeStep={activeStep} orientation="vertical">
                      {approvedSteps.map((label, index) => (
                        <Step key={label}>
                          <StepLabel
                            StepIconComponent={CustomStepIcon} /* onClick={handleStep(index)} */
                          >
                            <S.StepperLabelGrid container>
                              <p className={`number ${activeStep >= index ? "active" : null}`}>{`0${
                                index + 1
                              }`}</p>
                              <Grid display="flex" flexDirection="column">
                                <p className="title">{label[0]}</p>
                                <p className="subTitle">{label[1]}</p>
                              </Grid>
                            </S.StepperLabelGrid>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </S.LeftBorderGrid>
                  <S.RightBorderGrid item flexDirection="column" md={7}>
                    <S.InputContainer container justifyContent="space-between">
                      <S.BorderedInputGrid
                        item
                        md={10.5}
                        lg={10.5}
                        xl={11}
                        container
                        flexDirection="column"
                        className={verifyInputClassname(0, formik.values.description)}
                      >
                        {activeStep === 0 && (
                          <S.Input
                            name="description"
                            id="description"
                            placeholder="Insira o link do arquivo em PDF"
                            InputProps={{
                              readOnly: activeStep !== 0,
                            }}
                            onChange={formik.handleChange}
                            value={formik.values.description}
                            error={Boolean(formik.errors.description)}
                            helperText={formik.errors.description}
                          />
                        )}
                        {activeStep !== 0 && (
                          <LinkInput str={formik.values.description}>
                            <S.Input
                              name="description"
                              id="description"
                              placeholder="Insira o link do arquivo em PDF"
                              InputProps={{
                                readOnly: activeStep !== 0,
                              }}
                              onChange={formik.handleChange}
                              value={formik.values.description}
                              error={Boolean(formik.errors.description)}
                              helperText={formik.errors.description}
                            />
                          </LinkInput>
                        )}
                      </S.BorderedInputGrid>
                      <Grid container item md={1.5} lg={1} justifyContent="flex-end">
                        {activeStep === 0 ? (
                          <S.EditButton
                            onClick={() =>
                              formik?.values?.description?.length > 0
                                ? formik.handleSubmit()
                                : formik.validateForm()
                            }
                          >
                            <RiArrowRightLine size="20px" color="#fff" />
                          </S.EditButton>
                        ) : (
                          <S.DoneButton onClick={() => handleStepDetailsModal(1)}>
                            <IoCheckmarkOutline size="20px" color="#fff" />
                          </S.DoneButton>
                        )}
                      </Grid>
                    </S.InputContainer>
                    {activeStep >= 1 && (
                      <S.InputContainer container justifyContent="space-between">
                        <S.BorderedInputGrid
                          item
                          md={10.5}
                          lg={10.5}
                          xl={11}
                          className={verifyInputClassname(1, formik.values.projectDefinition)}
                        >
                          {activeStep === 1 && (
                            <S.Input
                              name="projectDefinition"
                              id="projectDefinition"
                              placeholder="Sobre a construção do projeto"
                              InputProps={{
                                readOnly: activeStep !== 1,
                              }}
                              onChange={formik.handleChange}
                              value={formik.values.projectDefinition}
                              error={Boolean(formik.errors.projectDefinition)}
                              helperText={formik.errors.projectDefinition}
                            />
                          )}
                          {activeStep !== 1 && (
                            <LinkInput str={formik.values.projectDefinition}>
                              <S.Input
                                name="projectDefinition"
                                id="projectDefinition"
                                placeholder="Sobre a construção do projeto"
                                InputProps={{
                                  readOnly: activeStep !== 1,
                                }}
                                onChange={formik.handleChange}
                                value={formik.values.projectDefinition}
                                error={Boolean(formik.errors.projectDefinition)}
                                helperText={formik.errors.projectDefinition}
                              />
                            </LinkInput>
                          )}
                        </S.BorderedInputGrid>
                        <Grid container item md={1.5} lg={1} justifyContent="flex-end">
                          {activeStep === 1 ? (
                            <S.EditButton
                              onClick={() =>
                                formik?.values?.projectDefinition?.length > 0
                                  ? formik.handleSubmit()
                                  : formik.validateForm()
                              }
                            >
                              <RiArrowRightLine size="20px" color="#fff" />
                            </S.EditButton>
                          ) : (
                            <S.DoneButton onClick={() => handleStepDetailsModal(2)}>
                              <IoCheckmarkOutline size="20px" color="#fff" />
                            </S.DoneButton>
                          )}
                        </Grid>
                      </S.InputContainer>
                    )}
                    {activeStep >= 2 && (
                      <S.InputContainer container justifyContent="space-between">
                        <S.BorderedInputGrid
                          item
                          md={10.5}
                          lg={10.5}
                          xl={11}
                          className={verifyInputClassname(2, formik.values.productionFiles)}
                        >
                          {activeStep === 2 && (
                            <S.Input
                              name="productionFiles"
                              id="productionFiles"
                              placeholder="Insira o link do drive com os arquivos"
                              InputProps={{
                                readOnly: activeStep !== 2,
                              }}
                              onChange={formik.handleChange}
                              value={formik.values.productionFiles}
                              error={Boolean(formik.errors.productionFiles)}
                              helperText={formik.errors.productionFiles}
                            />
                          )}
                          {activeStep !== 2 && (
                            <LinkInput str={formik.values.productionFiles}>
                              <S.Input
                                name="productionFiles"
                                id="productionFiles"
                                placeholder="Insira o link do drive com os arquivos"
                                InputProps={{
                                  readOnly: activeStep !== 2,
                                }}
                                onChange={formik.handleChange}
                                value={formik.values.productionFiles}
                                error={Boolean(formik.errors.productionFiles)}
                                helperText={formik.errors.productionFiles}
                              />
                            </LinkInput>
                          )}
                        </S.BorderedInputGrid>
                        <Grid container item md={1.5} lg={1} justifyContent="flex-end">
                          {activeStep === 2 ? (
                            <S.EditButton
                              onClick={() =>
                                formik?.values?.productionFiles?.length > 0
                                  ? formik.handleSubmit()
                                  : formik.validateForm()
                              }
                            >
                              <RiArrowRightLine size="20px" color="#fff" />
                            </S.EditButton>
                          ) : (
                            <S.DoneButton onClick={() => handleStepDetailsModal(3)}>
                              <IoCheckmarkOutline size="20px" color="#fff" />
                            </S.DoneButton>
                          )}
                        </Grid>
                      </S.InputContainer>
                    )}
                    {activeStep >= 3 && (
                      <S.InputContainer container justifyContent="space-between">
                        <S.BorderedInputGrid
                          item
                          md={10.5}
                          lg={
                            activeStep !== 3 || creativeArr[tabValue]?.status === "REJECTED"
                              ? 10.5
                              : 9
                          }
                          xl={
                            activeStep !== 3 || creativeArr[tabValue]?.status === "REJECTED"
                              ? 11
                              : 9
                          }
                          className={
                            activeStep !== 3 || creativeArr[tabValue]?.status === "REJECTED"
                              ? "readOnly"
                              : ""
                          }
                        >
                          <S.Input
                            name="projectName"
                            id="projectName"
                            placeholder={reviewPlaceholder[creativeArr[tabValue]?.status]}
                            value={selectedCreative?.status === "ACCEPTED" ? "Aprovado" : ""}
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </S.BorderedInputGrid>
                        <Grid
                          container
                          item
                          md={1.5}
                          lg={
                            activeStep !== 3 || creativeArr[tabValue]?.status === "REJECTED" ? 1 : 3
                          }
                          justifyContent="flex-end"
                        >
                          {activeStep === 3 &&
                            creativeArr[tabValue]?.status === "AWAITING REVIEW" && (
                              <Grid container gap="10px" justifyContent="flex-end">
                                <S.RejectButton onClick={() => setOpenRejectModal(true)}>
                                  <IoCloseCircleOutline size="25px" color="#fff" />
                                </S.RejectButton>
                                <S.ApproveButton onClick={handleApproval}>
                                  <IoCheckmarkCircleOutline size="25px" color="#fff" />
                                </S.ApproveButton>
                              </Grid>
                            )}
                          {creativeArr[tabValue]?.status === "ACCEPTED" && (
                            <S.DoneButton onClick={() => handleStepDetailsModal(4)}>
                              <IoCheckmarkOutline size="20px" color="#fff" />
                            </S.DoneButton>
                          )}
                          {creativeArr[tabValue]?.status === "REJECTED" && (
                            <S.RejectButtonSmall onClick={() => handleStepDetailsModal(4)}>
                              <IoCloseCircleOutline size="20px" color="#fff" />
                            </S.RejectButtonSmall>
                          )}
                        </Grid>
                      </S.InputContainer>
                    )}
                    {activeStep >= 4 && (
                      <S.InputContainer container justifyContent="space-between">
                        <S.BorderedInputGrid
                          item
                          md={10.5}
                          lg={10.5}
                          xl={11}
                          className={verifyInputClassname(4, formik.values.finalFiles)}
                        >
                          {activeStep === 4 && (
                            <S.Input
                              name="finalFiles"
                              id="finalFiles"
                              placeholder="Insira o link do drive com o arquivo final"
                              InputProps={{
                                readOnly: activeStep !== 4,
                              }}
                              onChange={formik.handleChange}
                              value={formik.values.finalFiles}
                              error={Boolean(formik.errors.finalFiles)}
                              helperText={formik.errors.finalFiles}
                            />
                          )}
                          {activeStep !== 4 && (
                            <LinkInput str={formik.values.finalFiles}>
                              <S.Input
                                name="finalFiles"
                                id="finalFiles"
                                placeholder="Insira o link do drive com o arquivo final"
                                InputProps={{
                                  readOnly: activeStep !== 4,
                                }}
                                onChange={formik.handleChange}
                                value={formik.values.finalFiles}
                                error={Boolean(formik.errors.finalFiles)}
                                helperText={formik.errors.finalFiles}
                              />
                            </LinkInput>
                          )}
                        </S.BorderedInputGrid>
                        <Grid container item md={1.5} lg={1} justifyContent="flex-end">
                          {activeStep === 4 ? (
                            <S.EditButton
                              onClick={() =>
                                formik?.values?.finalFiles?.length > 0
                                  ? formik.handleSubmit()
                                  : formik.validateForm()
                              }
                            >
                              <RiArrowRightLine size="20px" color="#fff" />
                            </S.EditButton>
                          ) : (
                            <S.DoneButton onClick={() => handleStepDetailsModal(5)}>
                              <IoCheckmarkOutline size="20px" color="#fff" />
                            </S.DoneButton>
                          )}
                        </Grid>
                      </S.InputContainer>
                    )}
                  </S.RightBorderGrid>
                </S.FormGrid>
              </Form>
            </Formik>
          </>
        )}
      </S.ProjectCard>
      <Modal
        open={openRejectModal}
        onClose={() => {
          setOpenRejectModal(false);
          setRejectedMessage("");
        }}
        size="md"
      >
        <Grid container flexDirection="column" gap="20px">
          <p>Motivo da recusa</p>
          <S.InputGrid item>
            <S.Input
              placeholder="Descreva"
              value={rejectedMessage}
              onChange={(e) => setRejectedMessage(e.target.value)}
              error={!rejectedMessage}
              helperText={!rejectedMessage ? "Motivo da recusa é obrigatório" : ""}
            />
          </S.InputGrid>
          <Grid container justifyContent="flex-end">
            <ButtonCustom
              label="Salvar"
              disabled={!rejectedMessage}
              onClick={() => {
                setOpenRejectModal(false);
                handleReject();
              }}
              sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }}
            />
          </Grid>
        </Grid>
      </Modal>
      <Modal
        open={openStepDetailsModal}
        onClose={() => {
          setOpenStepDetailsModal(false);
          setStepDetailsNumber();
        }}
        size="sm"
      >
        <Grid container flexDirection="column">
          <Grid container flexDirection="column">
            <p style={{ fontSize: "22px" }}>
              {stepDetailsNumber ? approvedSteps[stepDetailsNumber - 1][0] : ""}
            </p>
            <p style={{ fontSize: "14px" }}>
              {stepDetailsNumber ? approvedSteps[stepDetailsNumber - 1][1] : ""}
            </p>
          </Grid>
          <Grid container flexDirection="column" gap="5px" marginTop={2} marginBottom={2}>
            {selectedCreative &&
              isValidUrl(
                selectedCreative[selectedCreative?.length - stepDetailsNumber]?.content
              ) && (
                <Link
                  to={
                    hasHttpProtocol(
                      selectedCreative[selectedCreative?.length - stepDetailsNumber]?.content
                    )
                      ? selectedCreative[selectedCreative?.length - stepDetailsNumber]?.content
                      : `https://${
                          selectedCreative[selectedCreative?.length - stepDetailsNumber]?.content
                        }`
                  }
                  target="_blank"
                  style={{ color: "#fff" }}
                >
                  <p style={{ overflowWrap: "anywhere", textDecoration: "underline" }}>
                    {selectedCreative[selectedCreative?.length - stepDetailsNumber]?.content}
                  </p>
                </Link>
              )}
            {selectedCreative &&
              !isValidUrl(
                selectedCreative[selectedCreative?.length - stepDetailsNumber]?.content
              ) && (
                <p style={{ overflowWrap: "anywhere" }}>
                  {selectedCreative[selectedCreative?.length - stepDetailsNumber]?.content}
                </p>
              )}
            <p>
              Submetida por:{" "}
              {selectedCreative
                ? selectedCreative[selectedCreative?.length - stepDetailsNumber]?.user?.name
                : ""}
            </p>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openRejectLogModal} onClose={() => setOpenRejectLogModal(false)} size="md">
        <Grid container flexDirection="column" gap="20px">
          <p>Recusas</p>
          <Grid container flexDirection="column" gap="20px" marginTop={3} marginBottom={6}>
            {rejectionLog.length === 0 && <p>Sem recusas para exibir</p>}
            {rejectionLog.length > 0 &&
              rejectionLog.map((rejection, index) => {
                return (
                  <S.RejectedLogGrid container justifyContent="space-between" key={index}>
                    <Grid display="flex" item md={1}>
                      <p className={`number`}>
                        {rejectionLog.length < 10
                          ? `0${rejectionLog.length - index}`
                          : rejectionLog.length - index}
                      </p>
                    </Grid>
                    <Grid display="flex" item flexDirection="column" md={10.5}>
                      {rejectionLog &&
                        isValidUrl(filterRejectedSteps(rejection?.creative?.steps)) && (
                          <Link
                            to={
                              hasHttpProtocol(filterRejectedSteps(rejection?.creative?.steps))
                                ? filterRejectedSteps(rejection?.creative?.steps)
                                : `https://${filterRejectedSteps(rejection?.creative?.steps)}`
                            }
                            target="_blank"
                            style={{ color: "#fff" }}
                          >
                            <p className="titleLink">
                              {filterRejectedSteps(rejection?.creative?.steps)}
                            </p>
                          </Link>
                        )}
                      {rejectionLog &&
                        !isValidUrl(filterRejectedSteps(rejection?.creative?.steps)) && (
                          <p className="title">{filterRejectedSteps(rejection?.creative?.steps)}</p>
                        )}
                      <p className="subTitle">{rejection?.details}</p>
                      <Grid container gap="5px">
                        <GoDotFill color="#a0aec0" size="18px" style={{ alignSelf: "center" }} />
                        <p className="author">Criativo: {rejection?.creative?.title}</p>
                        <RiCalendarLine
                          color="#a0aec0"
                          size="18px"
                          style={{ alignSelf: "center" }}
                        />
                        <p>{moment(rejection?.createdAt).format("DD [de] MMMM [de] YYYY")}</p>
                        <GoDotFill color="#a0aec0" size="18px" style={{ alignSelf: "center" }} />
                        <p className="author">Por: {rejection?.user?.name}</p>
                      </Grid>
                    </Grid>
                  </S.RejectedLogGrid>
                );
              })}
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openNewCreativeModal} onClose={() => setOpenNewCreativeModal(false)} size="md">
        <Grid container flexDirection="column" gap="20px">
          <p>Novo criativo</p>
          <Grid container flexDirection="column" gap="20px" marginTop={3}>
            <S.InputGrid item>
              <S.Input
                hiddenLabel
                name="title"
                id="title"
                type="text"
                placeholder="Nome do criativo"
                fullWidth
                onChange={(e) => setNewCreativeTitle(e.target.value)}
                value={newCreativeTitle}
              />
            </S.InputGrid>
            <Grid container justifyContent="flex-end" mt="15px">
              <ButtonCustom
                label="Salvar"
                type="submit"
                sx={{
                  width: "150px",
                  height: "40px !important",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
                onClick={createNewCreative}
              />
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default CreativeForm;
