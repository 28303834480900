import React from "react";
import * as S from "../style";
import { Grid } from "@mui/material";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from "utils/utils";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { RiArrowRightLine } from "react-icons/ri";
import { IoCheckmarkCircle, IoCheckmarkOutline } from "react-icons/io5";
import construction from "assets/construction.png";
import { capitalize } from "utils/utils";

const BudgetForm = ({ project }) => {
  return (
    <S.ProjectCard>
      <Grid container justifyContent="space-between">
        <S.FormTitleGrid container flexDirection="column" item md={5}>
          <p>Orçamento🚧</p>
          <p className="subTitle">Em atualização</p>
        </S.FormTitleGrid>
        <Grid display="flex" flexDirection="column" alignItems="flex-end" item md={7}>
          {project?.status === "ENDED" && (
            <S.FinishedProjectBadgeGrid item>
              <p>Projeto finalizado</p>
            </S.FinishedProjectBadgeGrid>
          )}
          <S.BadgeGrid item>
            <p>{capitalize(project?.type.toLowerCase())}</p>
          </S.BadgeGrid>
        </Grid>
        {/*         <Grid container>
          <Grid item md={2.5} xl={2.5}>
            <p>Lucro e Despesas</p>
          </Grid>
          <Grid container item justifyContent='space-between' md={9} xl={9}>
            <Grid item md={5.5} xl={5.5}>
              <S.InputGrid>
                <S.Input
                  name='earned'
                  id='earned'
                  placeholder='Lucro(R$)'
                  onChange={formik.handleChange}
                  value={formik.values.earned}
                  error={Boolean(formik.errors.earned)}
                  helperText={formik.errors.earned}
                  InputProps={{ inputComponent: MaskMoney }}
                />
              </S.InputGrid>
            </Grid>
            <Grid item md={5.5} xl={5.5}>
              <S.InputGrid>
                <S.Input
                  name='spent'
                  id='spent'
                  placeholder='Despesa(R$)'
                  onChange={formik.handleChange}
                  value={formik.values.spent}
                  error={Boolean(formik.errors.spent)}
                  helperText={formik.errors.spent}
                  InputProps={{ inputComponent: MaskMoney }}
                />
              </S.InputGrid>
            </Grid>
          </Grid>
        </Grid> */}
        {/*         <Grid container>
          <Grid item md={2.5} xl={2.5}>
            <p>Valor do Influencer</p>
          </Grid>
          <S.InfluencerTabsGrid item md={9} xl={9}>
            {project?.budget?.influencerPercentage.length === 0 &&
              <p>Sem influencers vinculados</p>
            }
            <Grid container flexDirection='column' gap='20px'>
              <Grid container>
                <S.TabsGrid>
                  <Tabs
                    orientation='horizontal'
                    value={tabValue}
                    onChange={handleSetTabValue}
                    sx={{ background: "transparent", display: "flex", justifyContent: "flex-end" }}
                    TabIndicatorProps={{ hidden: true }}
                  >
                    {project?.budget?.influencerPercentage?.map((influencer) => (
                      <S.InfluencerBadge label={influencer?.influencer?.name} className={tabValue === influencer?.influencer?.name ? "active" : null} key={influencer.influencer?.id} value={influencer.influencer?.name} />
                    ))}
                  </Tabs>
                </S.TabsGrid>
              </Grid>
              {project?.budget?.influencerPercentage?.map((influencer, index) => (
                tabValue === influencer.influencer.name
                  ?
                  <Grid container justifyContent='space-between' key={influencer.influencer?.id}>
                    <S.InputGrid item md={5.5}>
                      <S.Input
                        placeholder='Porcentagem do Influencer(%)'
                        value={influencerBudget[index] ?? ''}
                        onChange={(e) => updateInfluencerPercentageState(index, e.target.value)}
                        InputProps={{ inputComponent: MaskPercentage }}
                      />
                    </S.InputGrid>
                    <S.InputGrid item md={5.5}>
                      <S.Input
                        placeholder='Valor Pago (R$)'
                        value={String(calculateInfluencerValue(Number(String(influencerBudget[index]).replace(/%/g, '').replace(/,/g, ".")))).replace(/\./g, ',')}
                        InputProps={{ inputComponent: MaskMoney, readOnly: true }}
                      />
                    </S.InputGrid>
                  </Grid>
                  :
                  null
              ))}
            </Grid>
            {percentageError && <p className='error'>Porcentagem total não pode ultrapassar 100%</p>}
          </S.InfluencerTabsGrid>
        </Grid> */}
        {/*         <Grid item md={9} xl={9}>
          <Grid container justifyContent='space-between' gap='20px'>
            <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='paymentDate'>
              <S.Input
                hiddenLabel
                type='text'
                placeholder="Data de Pagamento"
                fullWidth
                value={formik.values.paymentDate}
                data-input
                autoComplete='off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RiCalendarEventFill />
                    </InputAdornment>
                  )
                }}
              />
            </S.DateInputGrid>
            <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='receivingDate'>
              <S.Input
                hiddenLabel
                type='text'
                placeholder="Recebimento da NF"
                fullWidth
                value={formik.values.receivingDate}
                data-input
                autoComplete='off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RiCalendarEventFill />
                    </InputAdornment>
                  )
                }}
              />
            </S.DateInputGrid>
            <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='emissionDate'>
              <S.Input
                hiddenLabel
                type='text'
                placeholder="Emissão da NF"
                fullWidth
                value={formik.values.emissionDate}
                data-input
                autoComplete='off'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <RiCalendarEventFill />
                    </InputAdornment>
                  )
                }}
              />
            </S.DateInputGrid>
          </Grid>
        </Grid> */}
      </Grid>
      <S.FormGrid container justifyContent="center">
        <img src={construction} />
      </S.FormGrid>
    </S.ProjectCard>
  );
};

export default BudgetForm;
