import { useState, useEffect } from 'react';
import { Grid } from '@mui/material'
import { Formik, Form, useFormik } from 'formik';
import * as S from './style'
import * as Yup from "yup";
import showToast from 'components/Toast/Toast';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import "assets/theme/custom-flatpickr.css";
import { getInfluencerTags } from 'utils/requests/auth';
import { createProduct } from '../product';
import 'moment/locale/pt-br';
import { useNavigate } from "react-router-dom";
import Autocomplete from '@mui/material/Autocomplete';
import { editProductPhoto } from '../product';
import { IoAlertCircleOutline } from 'react-icons/io5';
import MaskMoney from 'components/Masks/MaskMoney';
import { MdCloudUpload } from "react-icons/md";
import { useFilePicker } from 'react-sage';
import DeleteIcon from '@mui/icons-material/Delete';
import Switch from '@mui/material/Switch';

const CreateProduct = ({ product }) => {
  const [tags, setTags] = useState();
  const [validateFields, setValidateFields] = useState(false);
  const [productImage, setProductImage] = useState('')
  const [previewUrl, setPreviewUrl] = useState(product?.backgroundPhoto ?? null);

  const navigate = useNavigate();

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setProductImage(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const deleteProductImage = () => {
    setProductImage('');
    setPreviewUrl(null);
  }; 

  const fetchTags = async () => {
    const data = await getInfluencerTags();
    if (!data.statusCode) setTags(data);
  };

  useEffect(() => {
    fetchTags();
  }, []);

  const validation = useFormik({
    validateOnChange: validateFields,
    validateOnBlur: false,

    initialValues: {
      name: '',
      description: '',
      productTags: [],
      metaName: '',
      price: '',
      stock: null,
      visibility: false
    },
    validationSchema: Yup.object({
      name: Yup.string().min(4).required("Nome é obrigatório"),
      description: Yup.string(),
      productTags: Yup.array(),
      metaName: Yup.string(),
      price: Yup.string(),
      stock: Yup.array(),
      visibility: Yup.boolean()
    }),
    onSubmit: async (values) => {
      const productValues = {
        name: values.name ? values.name : '',
        description: values.description ? values.description : '',
        productTags: values.productTags ? values.productTags.map(tag => tag.id) : '',
        metaName: values.metaName ? values.metaName : '',
        price: values.price ? values.price : '',
        stock: values.stock ? values.stock.map(item => item.name) : [],
        visibility: values.visibility
      };

      const productImageChange = {
        file: productImage ?? '',
      };

      Object.keys(productValues).forEach(key => {
        if (productValues[key] === '') {
          delete productValues[key];
        };
      });

      Object.keys(productValues).forEach(key => {
        if ((Array.isArray(productValues[key])) && (productValues[key].length === 0)) {
          delete productValues[key];
        };
      });

      if (Object.keys(productValues).length > 0) {
        await createProduct({ ...productValues }).then((res) => {
          if (res?.message) {
            showToast(res?.message, 'error');
          };
          if (!res?.message && !productImage) {
            showToast('Produto criado com sucesso! Redirecionando...', 'success');
            setTimeout(() => {
              navigate(`/ecommerce/produtos}`);
            }, 3000);
          };
          if (!res?.message && productImage) {
            editProductPhoto({ ...productImageChange }).then((photoResponse) => {
              if (!photoResponse?.message) {
                showToast('Produto criado com sucesso! Redirecionando...', 'success');
                setTimeout(() => {
                  navigate(`/ecommerce/produtos}`);
                }, 3000);
              };
              if (photoResponse?.message) {
                showToast("Houve um erro ao armazenar a foto deste produto, contate o suporte", "error")
              };
            });
          };
        });
      };
      if (Object.keys(productValues).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning');
    }
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleSwitchChange = (event) => {
    validation.setFieldValue('visibility', event.target.checked);
  };

  return (
    <Grid container flexDirection='column'>
      <p>Cadastro de Produto</p>
      <Formik initialValues={validation.initialValues} validationSchema={validation.validationSchema} onSubmit={validation.handleSubmit}>
        <Form>
          <Grid container flexDirection='column' gap='20px' mt='15px'>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  name='name'
                  placeholder="Nome do Produto*"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.name || ""}
                  error={validation.errors.name}
                  helperText={validation.errors.name ? 'Nome inválido' : false}
                />
              </S.InputGrid>
            </Grid>
            <Grid container gap='20px' justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  multiline
                  name='description'
                  placeholder="Descrição"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.description || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <Autocomplete
                  disablePortal
                  disableCloseOnSelect
                  multiple
                  name='productTags'
                  id='productTags'
                  value={validation.values.productTags ?? []}
                  onChange={(e, value) => {
                    validation.setFieldValue('productTags', value)
                  }}
                  options={tags ? tags : []}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder='Categorias' />}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option?.name === value?.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} xl={12}>
                <S.Input
                  hiddenLabel
                  name='metaName'
                  placeholder="Meta Nome"
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.metaName || ""}
                />
              </S.InputGrid>
            </Grid>
            <Grid container justifyContent='space-between'>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <S.Input
                  name='value'
                  hiddenLabel
                  placeholder={"Valor(R$)"}
                  fullWidth
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.value}
                  InputProps={{ inputComponent: MaskMoney }}
                />
              </S.InputGrid>
              <S.InputGrid item xs={12} md={5.85} xl={5.85}>
                <Autocomplete
                  name='stock'
                  disablePortal
                  value={validation.values.stock ? validation.values.stock : null}
                  onChange={(e, value) => {
                    validation.setFieldValue('stock', value)
                  }}
                  id="combo-box-demo"
                  options={[
                    { name: "Pré-venda" },
                    { name: "Estoque" },
                    { name: "Sem estoque" }
                  ]}
                  sx={{ width: "100%" }}
                  renderInput={(params) => <S.Input {...params} placeholder='Estoque' />}
                  getOptionLabel={(option) => option.name}
                  isOptionEqualToValue={(option, value) => option.name === value.name}
                />
              </S.InputGrid>
            </Grid>
            <Grid item md={12} xl={12}>
              <S.ImageUploadContainer container onClick={imageInput.onClick} sx={{ border: (productImage || previewUrl) ? 'transparent' : '0.4px solid rgba(255, 255, 255, 0.50)' }}>
                {!productImage && !previewUrl &&
                  <S.ImageUploadGrid container justifyContent='center' flexDirection='column' alignItems='center'>
                    <MdCloudUpload color='white' size='60px' />
                    <p>Enviar imagens do produto</p>
                    <p>Tipos de arquivos compatíveis: .jpg, .jpeg, .png</p>
                    <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png, .mp4" />
                  </S.ImageUploadGrid>
                }
                {(productImage || previewUrl) &&
                  <>
                    <img src={previewUrl} className='previewImg' alt="Preview da imagem" />
                    <S.DeleteFab size="small" onClick={() => deleteProductImage()}>
                      <DeleteIcon />
                    </S.DeleteFab>
                  </>
                }
              </S.ImageUploadContainer>
            </Grid>
            <Grid container justifyContent="space-between" alignItems="center">
              <p>Visibilidade do produto:</p>
              <Switch
                checked={validation.values.visibility}
                onChange={handleSwitchChange}
                inputProps={{ 'aria-label': 'Controle de visibilidade do produto' }}
              />
            </Grid>
            <S.AlertGrid container gap='5px' mt='15px'>
              <IoAlertCircleOutline size='18px' color='red' style={{ alignSelf: "center" }} />
              <p className='bold'>Lembrete: </p>
              <p>* indica um campo obrigatório.</p>
            </S.AlertGrid>
          </Grid>
          <Grid container justifyContent='flex-end' mt='15px'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", height: "40px !important", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
            />
          </Grid>
        </Form>
      </Formik>
    </Grid>
  )
}

export default CreateProduct