import React from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import Card from "@mui/material/Card";
import { Grid, Box } from "@mui/material";
import filter from "assets/icons/filter.svg";
import * as S from "./style";
import { IoArrowUp, IoArrowDown, IoAlertCircleOutline } from "react-icons/io5";
import instagram from "assets/icons/socials/instagramwhite.svg";
import tiktok from "assets/icons/socials/tiktokwhite.svg";
import twitter from "assets/icons/socials/twitterwhite.svg";
import youtube from "assets/icons/socials/youtubewhite.svg";
import spotify from "assets/icons/socials/spotifywhite.svg";
import LineChart from "defaultComponents/Charts/LineCharts/LineChart";
import { TokenContext } from "context/TokenContext";
import { useParams } from "react-router-dom";
import { RiAddFill } from "react-icons/ri";
import Modal from "components/Modal/Modal";

import moment from "moment";
import "moment/locale/pt-br";

import "assets/theme/custom-flatpickr.css";

import { getMetrics } from "utils/requests/other";
import Datepicker from "components/Datepicker/Datepicker";
import AddMetricsForm from "./AddMetricsForm";
import { abbreviateNumbers } from "utils/utils";

const Metrics = () => {
  moment.locale("pt-br");

  const params = useParams();

  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [initialDates, setInitialDates] = React.useState([]);
  const [socialData, setSocialData] = React.useState();
  const [open, setOpen] = React.useState(false);
  const [followersChartData, setFollowersChartData] = React.useState([]);
  const [impressionsChartData, setImpressionsChartData] = React.useState([]);
  const [reachChartData, setReachChartData] = React.useState([]);
  const [chartOptions, setChartOptions] = React.useState({
    chart: {
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      theme: "dark",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      categories: [
        "Jan",
        "Fev",
        "Mar",
        "Abr",
        "Mai",
        "Jun",
        "Jul",
        "Ago",
        "Set",
        "Out",
        "Nov",
        "Dez",
      ],
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: "#c8cfca",
          fontSize: "10px",
        },
      },
    },
    legend: {
      show: false,
    },
    grid: {
      strokeDashArray: 5,
      borderColor: "#56577A",
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "vertical",
        shadeIntensity: 0,
        gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 0,
        stops: [],
      },
      colors: ["#FC6009", "#F8DF00", "#33CCFF", "#FF0000", "#00D554"],
    },
    colors: ["#FC6009", "#F8DF00", "#33CCFF", "#FF0000", "#00D554"],
  });
  const [openConstruction, setOpenConstruction] = React.useState(false);

  const fetchData = async () => {
    const startDate = moment(initialDates[0], "DD-MM-YYYY HH:mm").valueOf();
    const endDate = moment(initialDates[1], "DD-MM-YYYY HH:mm").valueOf();
    const data = await getMetrics(params?.influencer /* , startDate, endDate */);
    if (!data.message) {
      setSocialData(data);
      setFollowersChartData(data?.followers?.graph);
      setImpressionsChartData(data?.impressions?.graph);
      setReachChartData(data?.reach?.graph);
      setChartOptions((chartOptions) => ({
        ...chartOptions,
        xaxis: {
          ...chartOptions.xaxis,
          categories: data?.categories,
        },
      }));
    }
  };

  React.useEffect(() => {
    if (initialDates.length > 0) fetchData();
  }, [initialDates]);

  const redirects = {
    ADMINISTRATOR: {
      instagram: `/influencers/${params?.influencer}/metricas/instagram`,
    },
    H_COMERCIAL: {
      instagram: `/influencers/${params?.influencer}/metricas/instagram`,
    },
    COMERCIAL: {
      instagram: `/influencers/${params?.influencer}/metricas/instagram`,
    },
    H_MARKETING: {
      instagram: `/influencers/${params?.influencer}/metricas/instagram`,
    },
    MARKETING: {
      instagram: `/influencers/${params?.influencer}/metricas/instagram`,
    },
    INFLUENCER: {
      instagram: "/metricas/instagram",
    },
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing="18px">
        <Grid item sm={12} md={12} lg={12} xl={12}>
          <Grid container justifyContent="space-between">
            <Datepicker setDate={setInitialDates} />
            <S.UpdateButtonGrid>
              <S.EditButton>
                <img src={filter} />
              </S.EditButton>
            </S.UpdateButtonGrid>
          </Grid>
        </Grid>
        <Grid item xl={12}>
          <Card>
            <S.BoardGrid container justifyContent="space-between">
              <S.ProgressGrid item xl={3}>
                <Grid container justifyContent="space-between">
                  <p className="followers">Seguidores</p>
                  <Grid item display="flex">
                    <p>{socialData?.followers?.total ?? 0}</p>
                    <IoArrowUp color="#65EFAD" size="22px" />
                  </Grid>
                </Grid>
                <S.BarContainer container>
                  <div className="bar">
                    <div className="instagram" style={{ width: "40%" }} />
                    <div className="tiktok" style={{ width: "20%" }} />
                    <div className="twitter" style={{ width: "20%" }} />
                    <div className="youtube" style={{ width: `15%` }} />
                    <div className="spotify" style={{ width: "5%" }} />
                  </div>
                </S.BarContainer>
              </S.ProgressGrid>
              <S.SocialCardsGrid item container xl={7}>
                <S.PageLink to={redirects[role]?.instagram}>
                  <S.SocialGrid className="instagram" item xl={2}>
                    <S.SocialNameGrid container justifyContent="center" gap="2px">
                      <img width="16px" src={instagram} />
                      <p>Instagram</p>
                    </S.SocialNameGrid>
                    <Grid container justifyContent="center">
                      <p>
                        {socialData
                          ? abbreviateNumbers(socialData?.followers?.changes?.Instagram?.changes ?? 0, 2)
                          : ""}
                      </p>
                      {socialData && socialData?.followers?.changes?.Instagram?.up ? (
                        <IoArrowUp color="#65EFAD" size="22px" />
                      ) : (
                        <IoArrowDown color="#D7007B" size="22px" />
                      )}
                    </Grid>
                  </S.SocialGrid>
                </S.PageLink>
                <S.SocialGrid
                  className="tiktok"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={tiktok} />
                    <p>TikTok</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData
                        ? abbreviateNumbers(socialData?.followers?.changes?.TikTok?.changes ?? 0, 2)
                        : ""}
                    </p>
                    {socialData && socialData?.followers?.changes?.TikTok?.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="twitter"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={twitter} />
                    <p>Twitter</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData
                        ? abbreviateNumbers(socialData?.followers?.changes?.Twitter?.changes ?? 0, 2)
                        : ""}
                    </p>
                    {socialData && socialData?.followers?.changes?.Twitter?.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="youtube"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={youtube} />
                    <p>Youtube</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData
                        ? abbreviateNumbers(socialData?.followers?.changes?.Youtube?.changes ?? 0, 2)
                        : ""}
                    </p>
                    {socialData && socialData?.followers?.changes?.Youtube?.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="spotify"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={spotify} />
                    <p>Spotify</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData
                        ? abbreviateNumbers(socialData?.followers?.changes?.Spotify?.changes ?? 0, 2)
                        : ""}
                    </p>
                    {socialData && socialData?.followers?.changes?.Spotify?.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
              </S.SocialCardsGrid>
            </S.BoardGrid>
            <Grid item xl={12}>
              <VuiBox sx={{ height: "250px" }}>
                <LineChart
                  key={followersChartData}
                  lineChartData={followersChartData}
                  lineChartOptions={chartOptions}
                />
              </VuiBox>
            </Grid>
          </Card>
        </Grid>
        <Grid item xl={12}>
          <Card>
            <Grid container justifyContent="space-between">
              <S.ProgressGrid item xl={3}>
                <Grid container justifyContent="space-between">
                  <p className="followers">Impressões</p>
                  <Grid item display="flex">
                    <p>{socialData?.impressions?.total ?? 0}</p>
                    <IoArrowUp color="#65EFAD" size="22px" />
                  </Grid>
                </Grid>
                <S.BarContainer container>
                  <div className="bar">
                    <div className="instagram" style={{ width: "40%" }} />
                    <div className="tiktok" style={{ width: "20%" }} />
                    <div className="twitter" style={{ width: "20%" }} />
                    <div className="youtube" style={{ width: `15%` }} />
                    <div className="spotify" style={{ width: "5%" }} />
                  </div>
                </S.BarContainer>
              </S.ProgressGrid>
              <S.SocialCardsGrid item container xl={7}>
                <S.PageLink to={redirects[role]?.instagram}>
                  <S.SocialGrid className="instagram" item xl={2}>
                    <S.SocialNameGrid container justifyContent="center" gap="2px">
                      <img width="16px" src={instagram} />
                      <p>Instagram</p>
                    </S.SocialNameGrid>
                    <Grid container justifyContent="center">
                      <p>
                        {socialData
                          ? abbreviateNumbers(socialData[0]?.reactions?.value ?? 0, 2)
                          : ""}
                      </p>
                      {socialData && socialData[0]?.reactions?.lastMonthChanges.up ? (
                        <IoArrowUp color="#65EFAD" size="22px" />
                      ) : (
                        <IoArrowDown color="#D7007B" size="22px" />
                      )}
                    </Grid>
                  </S.SocialGrid>
                </S.PageLink>
                <S.SocialGrid
                  className="tiktok"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={tiktok} />
                    <p>TikTok</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[1]?.reactions?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[1]?.reactions?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="twitter"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={twitter} />
                    <p>Twitter</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[2]?.reactions?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[2]?.reactions?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="youtube"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={youtube} />
                    <p>Youtube</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[3]?.reactions?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[3]?.reactions?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="spotify"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={spotify} />
                    <p>Spotify</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[4]?.reactions?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[4]?.reactions?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
              </S.SocialCardsGrid>
            </Grid>
            <Grid item xl={12}>
              <VuiBox sx={{ height: "250px" }}>
                <LineChart
                  key={impressionsChartData}
                  lineChartData={impressionsChartData}
                  lineChartOptions={chartOptions}
                />
              </VuiBox>
            </Grid>
          </Card>
        </Grid>
        <Grid item xl={12}>
          <Card>
            <Grid container justifyContent="space-between">
              <S.ProgressGrid item xl={3}>
                <Grid container justifyContent="space-between">
                  <p className="followers">Alcance</p>
                  <Grid item display="flex">
                    <p>{socialData?.reach?.total ?? 0}</p>
                    <IoArrowUp color="#65EFAD" size="22px" />
                  </Grid>
                </Grid>
                <S.BarContainer container>
                  <div className="bar">
                    <div className="instagram" style={{ width: "40%" }} />
                    <div className="tiktok" style={{ width: "20%" }} />
                    <div className="twitter" style={{ width: "20%" }} />
                    <div className="youtube" style={{ width: `15%` }} />
                    <div className="spotify" style={{ width: "5%" }} />
                  </div>
                </S.BarContainer>
              </S.ProgressGrid>
              <S.SocialCardsGrid item container xl={7}>
                <S.PageLink to={redirects[role]?.instagram}>
                  <S.SocialGrid className="instagram" item xl={2}>
                    <S.SocialNameGrid container justifyContent="center" gap="2px">
                      <img width="16px" src={instagram} />
                      <p>Instagram</p>
                    </S.SocialNameGrid>
                    <Grid container justifyContent="center">
                      <p>
                        {socialData ? abbreviateNumbers(socialData[0]?.reach?.value ?? 0, 2) : ""}
                      </p>
                      {socialData && socialData[0]?.reach?.lastMonthChanges.up ? (
                        <IoArrowUp color="#65EFAD" size="22px" />
                      ) : (
                        <IoArrowDown color="#D7007B" size="22px" />
                      )}
                    </Grid>
                  </S.SocialGrid>
                </S.PageLink>
                <S.SocialGrid
                  className="tiktok"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={tiktok} />
                    <p>TikTok</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[1]?.reach?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[1]?.reach?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="twitter"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={twitter} />
                    <p>Twitter</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[2]?.reach?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[2]?.reach?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="youtube"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={youtube} />
                    <p>Youtube</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[3]?.reach?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[3]?.reach?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
                <S.SocialGrid
                  className="spotify"
                  item
                  xl={2}
                  onClick={() => setOpenConstruction(true)}
                >
                  <S.SocialNameGrid container justifyContent="center" gap="2px">
                    <img width="16px" src={spotify} />
                    <p>Spotify</p>
                  </S.SocialNameGrid>
                  <Grid container justifyContent="center">
                    <p>
                      {socialData ? abbreviateNumbers(socialData[4]?.reach?.value ?? 0, 2) : ""}
                    </p>
                    {socialData && socialData[4]?.reach?.lastMonthChanges.up ? (
                      <IoArrowUp color="#65EFAD" size="22px" />
                    ) : (
                      <IoArrowDown color="#D7007B" size="22px" />
                    )}
                  </Grid>
                </S.SocialGrid>
              </S.SocialCardsGrid>
            </Grid>
            <Grid item xl={12}>
              <VuiBox sx={{ height: "250px" }}>
                <LineChart
                  key={reachChartData}
                  lineChartData={reachChartData}
                  lineChartOptions={chartOptions}
                />
              </VuiBox>
            </Grid>
          </Card>
        </Grid>
      </Grid>
      {role === "ADMINISTRATOR" && (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpen(true)}>
            <RiAddFill size="30px" />
          </S.ModalFab>
        </Box>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <AddMetricsForm />
      </Modal>
      <Modal size="md" open={openConstruction} onClose={() => setOpenConstruction(false)}>
        <S.ConstructionModalGrid container flexDirection="column">
          <Grid container justifyContent="center" sx={{ fontSize: "32px" }}>
            <p>🚧</p>
            <p>Em manutenção</p>
            <p>🚧</p>
          </Grid>
          <S.WarningGrid container gap="5px">
            <IoAlertCircleOutline color="red" style={{ alignSelf: "center" }} />
            <p className="subtitle reminder">Lembrete:</p>
            <p className="subtitle">Estamos batalhando por essa novidade T.T</p>
          </S.WarningGrid>
        </S.ConstructionModalGrid>
      </Modal>
    </DashboardLayout>
  );
};

export default Metrics;
