import React from "react";
import * as S from "../style";
import { Grid } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { RiCalendarEventFill } from "react-icons/ri";
import { Formik, Form, useFormik } from "formik";
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from "utils/utils";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import MaskMoney from "components/Masks/MaskMoney";
import MaskPercentage from "components/Masks/MaskPercentage";
import Tabs from "@mui/material/Tabs";
import moment from "moment";
import "moment/locale/pt-br";
import showToast from "components/Toast/Toast";
import { editProjectBudget } from "utils/requests/project";
import { useParams } from "react-router-dom";
import { capitalize } from "utils/utils";

const CommercialForm = ({ project, refetch }) => {
  moment.locale("pt-br");

  const params = useParams();

  const [tabValue, setTabValue] = React.useState(
    project?.budget?.influencerPercentage[0]?.influencer?.name
  );
  const [influencerBudget, setInfluencerBudget] = React.useState(
    project?.budget?.influencerPercentage?.map((influencer) => `${influencer.percentageNumber}%`) ??
      []
  );
  const [percentageError, setPercentageError] = React.useState(false);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const unixDate = (date) => {
    return Number(moment(date, "DD/MM/YYYY HH:mm").valueOf());
  };

  const cleanValue = (value) => {
    if (typeof value === "string") {
      return Number(value.replace(/[a-zA-Z$]+|\./g, "").replace(/,/g, ".")) * 100;
    }
    return value;
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      value: project?.budget?.value
        ? Number((project?.budget?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 0,
      earned: project?.budget?.earned
        ? Number((project.budget?.earned / 100).toFixed(2)).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 0,
      spent: project?.budget?.spent
        ? Number((project.budget?.spent / 100).toFixed(2)).toLocaleString("pt-BR", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : 0,
      description: project?.description ?? "",
      paymentDate: project?.budget?.paymentDate
        ? moment(project?.budget?.paymentDate).format("DD/MM/YYYY HH:mm")
        : "",
      paymentDateExpected: project?.budget?.paymentDateExpected
        ? moment(project?.budget?.paymentDateExpected).format("DD/MM/YYYY HH:mm")
        : "",
      seller: project?.creator,
    },
    validationSchema: Yup.object({
      value: Yup.string(),
      earned: Yup.string(),
      spent: Yup.string(),
      description: Yup.string(),
      paymentDate: Yup.string() /* .required('Data de Pagamento é obrigatório') */,
      paymentDateExpected: Yup.string() /* .required('Data de Recebimento de NF é obrigatório') */,
      seller: Yup.object(),
    }),
    onSubmit: async (values) => {
      const startProjectValues = formik.initialValues;
      /*       const budgetToEdit = {
        budget: cleanValue(values.value) !== startProjectValues.value ? cleanValue(values.value) : null,
        earned: cleanValue(values.earned) !== startProjectValues.earned ? cleanValue(values.earned) : null,
        spent: cleanValue(values.spent) !== startProjectValues.spent ? cleanValue(values.spent) : null,
        description: values.description !== startProjectValues.description ? values.description : null,
        influencerPercentage: project?.influencers?.map((influencer, index) => ({
          influencerId: influencer.id,
          percentage: Number(String(influencerBudget[index]?.replace(/%/g, '').replace(/,/g, ".")))
        })),
        paymentDate: values.paymentDate !== startProjectValues.paymentDate ? unixDate(values.paymentDate) : null,
        invoiceBillReceived: values.receivingDate !== startProjectValues.receivingDate ? unixDate(values.receivingDate) : null,
        invoiceBillIssued: values.emissionDate !== startProjectValues.emissionDate ? unixDate(values.emissionDate) : null,
      };
      Object.keys(budgetToEdit).forEach(key => {
        if (budgetToEdit[key] === null) {
          delete budgetToEdit[key];
        };
      });
      if (Object.keys(budgetToEdit).length > 0) await editProjectBudget(project?.budget?.id, budgetToEdit).then((res) => {
        if (res.message) {
          showToast('Falha na edição do projeto', 'error');
        };
        if (!res.message) {
          showToast('Projeto editado com sucesso!', 'success');
          refetch();
        };
      });
      if (Object.keys(budgetToEdit).length === 0) showToast('Sem dados alterados, campos sem alterações', 'warning'); */
    },
  });

  /*   flatpickr(".paymentDate", flatpickrOptions('paymentDate', formik.setFieldValue));
  flatpickr(".paymentDateExpected", flatpickrOptions('paymentDateExpected', formik.setFieldValue)); */

  const updateInfluencerPercentageState = (index, value) => {
    const newArr = [...influencerBudget];
    const cleanedValue = value;
    newArr[index] = cleanedValue;
    setInfluencerBudget(newArr);
  };

  const calculateInfluencerValue = (percentage) => {
    let value;
    if (typeof formik?.values?.value === "number") {
      value = formik?.values?.value;
    }
    if (typeof formik?.values?.value === "string") {
      value = cleanValue(formik?.values?.value) / 100;
    }
    const result = (percentage / 100) * value;
    return result;
  };

  const verifyTotalPercentage = () => {
    let result = 0;
    influencerBudget.map(
      (num) => (result = result + Number(String(num).replace(/%/g, "").replace(/,/g, ".")))
    );
    if (result > 100) {
      setPercentageError(true);
    }
    if (result <= 100 && percentageError) {
      setPercentageError(false);
    }
  };

  React.useEffect(() => {
    verifyTotalPercentage();
  }, [influencerBudget]);

  return (
    <S.ProjectCard>
      <Grid container justifyContent="space-between">
        <S.FormTitleGrid container flexDirection="column" item md={5}>
          <p>Comercial</p>
          <p className="subTitle">Informações da venda do projeto</p>
        </S.FormTitleGrid>
        <Grid display="flex" flexDirection="column" alignItems="flex-end" item md={7}>
          {project?.status === "ENDED" && (
            <S.FinishedProjectBadgeGrid item>
              <p>Projeto finalizado</p>
            </S.FinishedProjectBadgeGrid>
          )}
          <S.BadgeGrid item>
            <p>{capitalize(project?.type.toLowerCase())}</p>
          </S.BadgeGrid>
        </Grid>
      </Grid>
      <Formik
        initialValues={formik.initialValues}
        validationSchema={formik.validationSchema}
        onSubmit={formik.handleSubmit}
      >
        <Form>
          <S.FormGrid container flexDirection="column" gap="30px">
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Vendedor</p>
              </Grid>
              <Grid item md={9} xl={9}>
                <S.InputGrid>
                  <S.Input
                    name="seller"
                    id="seller"
                    placeholder="Vendedor"
                    onChange={formik.handleChange}
                    value={formik?.values?.seller?.name}
                    error={Boolean(formik.errors.seller)}
                    helperText={formik.errors.seller}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </S.InputGrid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Valor do Negócio</p>
              </Grid>
              <Grid item md={9} xl={9}>
                <S.InputGrid>
                  <S.Input
                    name="value"
                    id="value"
                    placeholder="Valor Geral (R$)"
                    onChange={formik.handleChange}
                    value={formik.values.value}
                    error={Boolean(formik.errors.value)}
                    helperText={formik.errors.value}
                    InputProps={{ inputComponent: MaskMoney }}
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </S.InputGrid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Descrição do negócio</p>
              </Grid>
              <Grid item md={9} xl={9}>
                <S.InputGrid>
                  <S.Input
                    placeholder="Descrição do negócio"
                    name="description"
                    id="description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    multiline
                    inputProps={{
                      readOnly: true,
                    }}
                  />
                </S.InputGrid>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Datas</p>
              </Grid>
              <Grid item md={9} xl={9}>
                <Grid container justifyContent="space-between" gap="20px">
                  <S.DateInputGrid item xs={12} md={5.5} xl={5.5}>
                    <S.Input
                      hiddenLabel
                      type="text"
                      placeholder="Data de Fechamento"
                      fullWidth
                      value={formik.values.paymentDateExpected}
                      data-input
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiCalendarEventFill />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </S.DateInputGrid>
                  <S.DateInputGrid item xs={12} md={5.5} xl={5.5}>
                    <S.Input
                      hiddenLabel
                      type="text"
                      placeholder="Data de Pagamento"
                      fullWidth
                      value={formik.values.paymentDate}
                      data-input
                      autoComplete="off"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <RiCalendarEventFill />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </S.DateInputGrid>
                </Grid>
              </Grid>
            </Grid>
          </S.FormGrid>
        </Form>
      </Formik>
    </S.ProjectCard>
  );
};

export default CommercialForm;
