import React, { useEffect } from 'react'
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import { Grid } from '@mui/material';
import * as S from '../../style'
import Autocomplete from '@mui/material/Autocomplete';
import { RiCalendarEventFill } from 'react-icons/ri'
import InputAdornment from '@mui/material/InputAdornment';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Modal from 'components/Modal/Modal';
import { getCategories, getSubCategories, createCategories, createFinancialInvoice } from 'utils/requests/financial';
import moment from 'moment';
import 'moment/locale/pt-br';
import MaskMoney from 'components/Masks/MaskMoney';
import showToast from 'components/Toast/Toast';
import FormHelperText from '@mui/material/FormHelperText';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { sanitizeCurrencyValue } from 'utils/utils';

const OutboundForm = ({ refetch, closeModal }) => {
  moment.locale("pt-br")

  const [newCategory, setNewCategory] = React.useState();
  const [newCategoryOpen, setNewCategoryOpen] = React.useState(false);
  const [allCategories, setAllCategories] = React.useState();
  const [allSubCategories, setAllSubCategories] = React.useState();
  const [validateFields, setValidateFields] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      value: '',
      date: '',
      description: '',
      bankAccount: '',
      category: null,
      subCategories: null,
      status: '',
      miscInfo: '',
    },

    validateOnChange: validateFields,
    validateOnBlur: false,

    validationSchema: Yup.object({
      value: Yup.string().required("Valor é um campo obrigatório"),
      date: Yup.string().required("Data é um campo obrigatório"),
      description: Yup.string().required('Descrição é um campo obrigatório').min(4, 'Descrição precisa ter pelo menos 4 caracteres'),
      bankAccount: Yup.string(),
      category: Yup.object().required('Categoria é um campo obrigatório'),
      subCategories: Yup.array().notRequired().nullable(),
      status: Yup.string().required('Status é um campo obrigatório'),
      miscInfo: Yup.string(),
    }),
    onSubmit: async (values) => {
      const unixDate = (date) => {
        return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
      };
      const newOutbound = {
        description: values.description,
        value: sanitizeCurrencyValue(values?.value),
        category: values?.category?.id,
        subCategories: values?.subCategories?.map(sub => sub.id),
        businessStatus: values.status,
        miscInfo: values.miscInfo,
        dateInvoicedMs: unixDate(values.date),
        bankAccount: values.bankAccount,
        type: 'SAIDA'
      }
      Object.keys(newOutbound).forEach(key => {
        if (newOutbound[key] === "" || newOutbound[key] === undefined) {
          delete newOutbound[key];
        }
      });
      createFinancialInvoice(newOutbound).then((res) => {
        if (!res.message) { showToast("Despesa criada com sucesso", "success"); refetch(); closeModal() };
        if (res.message) { showToast("Falha na criação de despesa", "error") };
      });
    }
  });

  const getAllCategories = async () => {
    const data = await getCategories(["SAIDA"]);
    if (!data.statusCode) {
      const categoriesArray = data;
      categoriesArray.push({ name: "Criar nova categoria +", id: "Criar nova categoria +" });
      setAllCategories(categoriesArray);
    };
  };

  const getAllSubCategories = async () => {
    const data = await getSubCategories(["SAIDA"]);
    if (!data.statusCode) {
      setAllSubCategories(data);
    };
  };


  const handleNewCategoryClose = () => {
    setNewCategoryOpen(false);
    formik.setFieldValue('category', null);
    setNewCategory('');
  };

  useEffect(() => {
    getAllCategories();
    getAllSubCategories();
  }, []);

  useEffect(() => {
    if (formik?.values?.category?.name === 'Criar nova categoria +') {
      setNewCategoryOpen(true);
    };
  }, [formik.values.category]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const onSaveNewCategory = async (name) => {
    await createCategories(name, "MAIN", 'SAIDA').then((res) => {
      if (!res.message) {
        showToast("Categoria criada com sucesso", "success");
        getAllCategories();
        formik.setFieldValue('category', res);
        setNewCategory('');
        setNewCategoryOpen(false)
      };
      if (res.message) {
        showToast("Falha na criação de categoria", "error")
      };
    });
  };

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  flatpickr("#date", flatpickrOptions('date', formik.setFieldValue, formik.setFieldTouched));
  flatpickr(".paymentDate", flatpickrOptions('paymentDate', formik.setFieldValue, formik.setFieldTouched));

  return (
    <>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit} validateOnChange validateOnBlur validateOnMount>
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid container flexDirection='column' gap='20px' marginTop='30px'>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='value'
                    hiddenLabel
                    placeholder={"Valor(R$)"}
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.value}
                    InputProps={{ inputComponent: MaskMoney }}
                    error={Boolean(formik.errors.value) && formik.touched.value}
                    helperText={formik.touched.value ? formik.errors.value : false}
                  />
                </S.InputGrid>
                <S.DateInputGrid item id='date' xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='date'
                    hiddenLabel
                    type='text'
                    placeholder="Data"
                    fullWidth
                    value={formik.values.date}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('date', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.date) && formik.touched.date}
                    helperText={formik.touched.date ? formik.errors.date : false}
                  />
                </S.DateInputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='description'
                    hiddenLabel
                    type='text'
                    placeholder="Descrição"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    error={Boolean(formik.errors.description) && formik.touched.description}
                    helperText={formik.touched.description ? formik.errors.description : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='bankAccount'
                    hiddenLabel
                    type='text'
                    placeholder="Conta Bancária"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.bankAccount}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <>
                  <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                    <Autocomplete
                      name='category'
                      disablePortal
                      value={formik.values.category ? formik.values.category : null}
                      onChange={(e, value) => {
                        formik.setFieldTouched('category')
                        formik.setFieldValue('category', value ? value : '')
                      }}
                      options={allCategories ? allCategories : []}
                      sx={{ width: "100%" }}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <S.Input {...params} placeholder='Categoria' />}
                      isOptionEqualToValue={(option, value) => option.id === value.id}
                    />
                    {(Boolean(formik.errors.category) && formik.touched.category) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>Categoria é um campo obrigatório</FormHelperText>}
                  </S.InputGrid>
                  <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                    <Autocomplete
                      name='subCategories'
                      disablePortal
                      disableCloseOnSelect
                      multiple
                      value={formik.values.subCategories ? formik.values.subCategories : []}
                      onChange={(e, value) => {
                        formik.setFieldValue('subCategories', value)
                      }}
                      id="combo-box-demo"
                      options={allSubCategories ? allSubCategories : []}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <S.Input {...params} placeholder='Sub-Categoria' />}
                      getOptionLabel={(option) => option.name}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option.name}
                        </li>
                      )}
                    />
                  </S.InputGrid>
                </>
              </Grid>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    hiddenLabel
                    name='miscInfo'
                    type='text'
                    placeholder="Observações"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.miscInfo}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff" }}>Status:</FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={formik.values.status}
                      onChange={handleStatusChange}
                    >
                      {Boolean(formik.errors.status) && formik.touched.status && <FormHelperText sx={{ color: "#f44336" }}>Status é um campo obrigatório</FormHelperText>}
                      <Grid container gap='30px' sx={{ marginLeft: "20px" }}>
                        <FormControlLabel value="PAGO" control={<Radio />} label="Pago" />
                        <FormControlLabel value="PENDENTE" control={<Radio />} label="Pendente" />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom
              label='Salvar'
              type='submit'
              sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }}
              onClick={() => setValidateFields(true)}
              data-cy="save-financial-expanse"
            />
          </Grid>
        </Form>
      </Formik>
      <Modal
        open={newCategoryOpen}
        onClose={handleNewCategoryClose}
        size={"sm"}
      >
        <Grid container flexDirection='column' gap='30px'>
          <p>Nova Categoria</p>
          <S.InputGrid item xl={12}>
            <S.Input
              hiddenLabel
              name='newCategory'
              type='text'
              placeholder="Nome da Categoria"
              fullWidth
              onChange={(event) => setNewCategory(event.target.value)}
              value={newCategory}
            />
          </S.InputGrid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom label='Salvar' onClick={() => onSaveNewCategory(newCategory)} sx={{ width: "100px", height: "40px !important", fontSize: "18px", fontWeight: "500" }} />
          </Grid>
        </Grid>
      </Modal>
    </>
  )
}

export default OutboundForm