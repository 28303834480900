import { TokenContext } from "context/TokenContext";
import { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as S from "./style";
import { editInfluencer } from "utils/requests/auth";

const MetaLogin = () => {
	const navigate = useNavigate();
  const { decodedToken } = useContext(TokenContext);
  const username = decodedToken?.username;
  const [searchParams, setSearchParams] = useSearchParams();

  const metaCode = searchParams.get("code") ?? "";
  const errorCode = searchParams.get("error_code") ?? "";
  const errorMessage = searchParams.get("error_message") ?? "";

  const patchMetaToken = async () => {
    await editInfluencer(username, {
			socialMediaLinks: [{
				apiToken: metaCode,
				socialMediaRelated: "instagram"
			}] 
		}
	).then((res) => {
      if (!res.message) {
				navigate('/dashboard')
      }
      if (res.message) {
				console.log(`Erro ${errorCode}: ${errorMessage}`);
      }
    });
  };

	useEffect(() => {
		if(metaCode){
			patchMetaToken();
		}
	}, [metaCode]);

  return (
    <>
      <S.Background container>
        <S.Loader />
      </S.Background>
    </>
  );
};
export default MetaLogin;
