import * as S from "../style";
import { Grid } from "@mui/material";
import "assets/theme/custom-flatpickr.css";
import { capitalize } from "utils/utils";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useEffect, useState } from "react";
import { getPipelineActivities } from "utils/requests/comercial";
import { hasHttpProtocol } from "utils/utils";

const ProductionForm = ({ project }) => {
  const [activities, setActivities] = useState([]);

  const fetchActivities = async () => {
    const data = await getPipelineActivities(project.id, true);
    if (!data.message) {
      setActivities(data);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  const teamsTranslation = {
    DESIGNER: "Designer",
    FILMMAKER: "Videomaker",
    SOCIAL_MEDIA: "Social Media",
    PRODUCER: "Produção",
  };

  return (
    <S.ProjectCard>
      <Grid container justifyContent="space-between" flexDirection="column">
        <Grid container justifyContent="space-between">
          <S.FormTitleGrid container flexDirection="column" item md={5}>
            <p>Produção</p>
            <p className="subTitle">Atividades de produção</p>
          </S.FormTitleGrid>
          <Grid
            display="flex"
            justifyContent="flex-end"
            alignItems="flex-end"
            item
            md={7}
            gap="10px"
          >
            {project?.status === "ENDED" && (
              <S.FinishedProjectBadgeGrid item>
                <p>Projeto finalizado</p>
              </S.FinishedProjectBadgeGrid>
            )}
            <S.TypeBadgeGrid item>
              <p>{capitalize(project?.type.toLowerCase())}</p>
            </S.TypeBadgeGrid>
          </Grid>
        </Grid>
        <Grid display="flex" flexDirection="column" alignItems="flex-end" gap="15px" mt="15px">
          {activities.map((card) => (
            <S.CheckboxAccordion sx={{ backgroundColor: "transparent !important" }} key={card?.id}>
              <S.CheckboxAccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
                sx={{ color: "#fff" }}
              >
                {card.title}
              </S.CheckboxAccordionSummary>
              <S.CheckboxAccordionDetails sx={{ color: "#fff" }}>
                <Grid container flexDirection="column" px="30px" gap="20px">
                  <Grid container justifyContent="space-between">
                    <Grid display="flex" item lg={4} flexDirection="column">
                      <p className="subTitle">Time</p>
                      <p className="content">{teamsTranslation[card?.team]}</p>
                    </Grid>
                    <Grid display="flex" item lg={4} flexDirection="column" pr="30px">
                      <p className="subTitle">Membros</p>
                      <p className="content">
                        {card?.members?.map((member) => member?.name).join(", ")}
                      </p>
                      {card?.members?.length === 0 && <p className="content">-</p>}
                    </Grid>
                    <Grid display="flex" item lg={4} flexDirection="column">
                      <p className="subTitle">Influencer</p>
                      <p className="content">
                        {card?.pipeline?.project?.influencers
                          ?.map((member) => member?.name)
                          .join(", ")}
                      </p>
                      {card?.pipeline?.project?.influencers?.length === 0 && (
                        <p className="content">-</p>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container flexDirection="column">
                    <p className="subTitle">Descrição</p>
                    <p className="content">{card?.description ?? "-"}</p>
                  </Grid>
                  <Grid container flexDirection="column">
                    <p className="subTitle">Anexos</p>
                    {card?.attachments?.map((attachment) => (
                      <a
                        href={hasHttpProtocol(attachment?.link) ? attachment?.link : `https://${attachment?.link}`}
                        className="content attachment"
                        key={attachment?.id}
                        target="_blank"
                      >
                        {attachment?.title}
                      </a>
                    ))}
                    {card.attachments?.length === 0 && <p className="content">-</p>}
                  </Grid>
                </Grid>
              </S.CheckboxAccordionDetails>
            </S.CheckboxAccordion>
          ))}
        </Grid>
      </Grid>
    </S.ProjectCard>
  );
};

export default ProductionForm;
