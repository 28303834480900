import { useState, useEffect, useContext } from 'react';
import * as S from '../style'
import { MdCloudUpload } from "react-icons/md";
import { useFilePicker } from 'react-sage';
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import { RiCalendarEventFill } from 'react-icons/ri';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom';
import moment from 'moment';
import 'moment/locale/pt-br';
import showToast from 'components/Toast/Toast';
import { editProjectGeneral } from 'utils/requests/project';
import { useParams } from 'react-router-dom';
import { capitalize } from 'utils/utils';
import pencil from 'assets/icons/pencil.png'
import { editProjectPipeline } from 'utils/requests/project';
import { getAllClients } from 'utils/requests/leads';
import FormHelperText from '@mui/material/FormHelperText';
import { uploadProjectFile } from 'utils/requests/project';
import { TokenContext } from "context/TokenContext";

const GeneralForm = ({ project, influencerList, refetch }) => {
  moment.locale("pt-br");

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const params = useParams();

  const [postAvatar, setPostAvatar] = useState('')
  const [previewUrl, setPreviewUrl] = useState(project?.backgroundPhoto ?? null);
  const [isFormEditable, setIsFormEditable] = useState(false);
  const [leadsArr, setLeadsArr] = useState();

  const fetchLeads = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setLeadsArr(data);
    };
  };

  useEffect(() => {
    fetchLeads();
  }, []);

  const editProject = async (projectToEdit, pipelineToEdit) => {
    if (pipelineToEdit) {
      await editProjectPipeline(params?.id, pipelineToEdit).then((res) => {
        if (res?.message) {
          showToast('Falha na edição do projeto', 'error');
          return
        };
      });
    };
    await editProjectGeneral(params?.id, projectToEdit).then((res) => {
      if (res?.message) {
        showToast('Falha na edição do projeto', 'error');
      };
      if (!res?.message) {
        showToast('Projeto editado com sucesso!', 'success');
        refetch();
      };
    });
  };

  const formik = useFormik({
    enableReinitialize: true,

    initialValues: {
      title: project?.title ?? '',
      lead: project?.pipeline?.leadsRelated,
      description: project?.description ?? '',
      influencers: project?.influencers ?? [],
      start: project?.startDate ? moment(project?.startDate).format('DD/MM/YYYY HH:mm') : '',
      end: project?.endDate ? moment(project?.endDate).format('DD/MM/YYYY HH:mm') : '',
      currency: project?.currency,
    },
    validationSchema: Yup.object({
      title: Yup.string().min(4, 'Nome do projeto deve conter pelo menos 4 caracteres').required('Nome do projeto é obrigatório'),
      lead: Yup.object().required("Empresa é um campo obrigatório"),
      description: Yup.string().min(4, 'Descrição deve conter pelo menos 4 caracteres'),
      influencers: Yup.array(),
      start: Yup.string().required('Data Inicial é obrigatório'),
      end: Yup.string(),
      currency: Yup.string().required('Moeda corrente é obrigatório'),
    }),
    onSubmit: async (values) => {
      const startProjectValues = formik.initialValues;
      const projectToEdit = {
        title: values.title !== startProjectValues.title ? values.title : null,
        /*         business: values.business !== startProjectValues.business ? values.business : null, */
        description: values.description !== startProjectValues.description ? values.description : null,
        influencers: values.influencers !== startProjectValues.influencers ? values.influencers.map((influencer) => influencer.id) : null,
        /*         startDate: values.start !== startProjectValues.start ? unixDate(values.start) : null,
                endDate: values.end !== startProjectValues.end ? unixDate(values.end) : null, */
      };

      const leadToEdit = {
        leadsRelated: values?.lead?.id !== startProjectValues?.lead.id ? values.lead.id : null,
      }

      Object.keys(projectToEdit).forEach(key => {
        if (projectToEdit[key] === null) {
          delete projectToEdit[key];
        };
      });

      Object.keys(leadToEdit).forEach(key => {
        if (leadToEdit[key] === null) {
          delete leadToEdit[key];
        };
      });

      const avatarChange = {
        file: postAvatar ?? '',
        id: project?.id
      };

      if(postAvatar){
        await uploadProjectFile(avatarChange).then((res) => {
          if (!res?.message && (Object.keys(leadToEdit).length === 0) && (Object.keys(projectToEdit).length === 0)) {
            showToast("Dados alterados com sucesso!", "success");
            refetch();
            setPostAvatar('');
            return
          };
          if (res.message && (Object.keys(leadToEdit).length === 0) && (Object.keys(projectToEdit).length === 0)) {
            showToast('Falha na edição do projeto', 'error');
            return
          };
          if ((res.message && (Object.keys(leadToEdit).length > 0)) || (res.message && (Object.keys(projectToEdit).length > 0))) {
            showToast('Falha na edição do projeto', 'error');
            return
          };
        });
      };

      if (Object.keys(leadToEdit).length > 0 && Object.keys(projectToEdit).length === 0) {
        await editProjectPipeline(params?.id, leadToEdit).then((res) => {
          if (res?.message) {
            showToast('Falha na edição do projeto', 'error');
          };
          if (!res?.message && Object.keys(projectToEdit).length === 0) {
            showToast('Projeto editado com sucesso!', 'success');
            refetch();
          };
          if (!res?.message && Object.keys(projectToEdit).length > 0) {
            editProject(projectToEdit)
          };
        });
      }

      if ((Object.keys(leadToEdit).length === 0) && (Object.keys(projectToEdit).length > 0)) {
        editProject(projectToEdit);
      };

      if (Object.keys(leadToEdit).length > 0 && Object.keys(projectToEdit).length > 0) {
        editProject(projectToEdit, leadToEdit)
      }

      if ((Object.keys(leadToEdit).length === 0) && (Object.keys(projectToEdit).length === 0) && (!postAvatar)) {
        showToast('Sem dados alterados, campos sem alterações', 'warning');
      };

    }
  });

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  flatpickr(".startDate", flatpickrOptions('start', formik.setFieldValue));

  flatpickr(".endDate", flatpickrOptions('end', formik.setFieldValue));

  const imageInput = useFilePicker({ maxFileSize: 1, resizeImage: true });

  useEffect(() => {
    const getDataUrls = async () => {
      if (imageInput.files && imageInput.files[0]) {
        setPostAvatar(imageInput.files[0]);
        const reader = new FileReader();
        reader.readAsDataURL(imageInput.files[0]);
        reader.onload = () => {
          setPreviewUrl(reader.result);
        };
      };
    };
    getDataUrls();
  }, [imageInput.files]);

  const deleteAvatar = () => {
    setPostAvatar('');
    setPreviewUrl(null);
  };

  return (
    <S.ProjectCard>
      <Grid container justifyContent='space-between'>
        <S.FormTitleGrid container flexDirection='column' item md={7}>
          <p>Informações gerais</p>
          <p className='subTitle'>Gerenciar informações básicas do projeto</p>
        </S.FormTitleGrid>
        <Grid display='flex' justifyContent='flex-end' alignItems='flex-end' item md={5} gap='10px'>
          {project?.status === "ENDED" &&
            <S.FinishedProjectBadgeGrid item>
              <p>Projeto finalizado</p>
            </S.FinishedProjectBadgeGrid>
          }
          <S.TypeBadgeGrid item>
            {/* <p>Campanhas</p> */}
            <p>{capitalize(project?.type.toLowerCase())}</p>
          </S.TypeBadgeGrid>
          {(project?.status !== "ENDED") && (role !== "MARKETING") &&
            <S.EditButton onClick={() => setIsFormEditable(!isFormEditable)}>
              <img src={pencil} />
            </S.EditButton>
          }
        </Grid>
      </Grid>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit}>
        <Form>
          <S.FormGrid container flexDirection='column' gap='30px'>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Foto de Capa</p>
              </Grid>
              <Grid item md={9} xl={9}>
                <S.ImageUploadContainer container onClick={imageInput.onClick} sx={{ border: isFormEditable ? '0.4px solid rgba(255, 255, 255, 0.50)' : 'transparent' }}>
                  {!postAvatar && !previewUrl && isFormEditable &&
                    <S.ImageUploadGrid container justifyContent='center' flexDirection='column' alignItems='center'>
                      <MdCloudUpload color='white' size='60px' />
                      <p>Enviar vídeo ou Imagem</p>
                      <p>Tipos de arquivos compatíveis: .jpg, .jpeg, .png</p>
                      <imageInput.HiddenFileInput accept=".jpg, .jpeg, .png, .mp4" />
                    </S.ImageUploadGrid>
                  }
                  {(postAvatar || previewUrl) &&
                    <>
                      <img src={previewUrl} className='previewImg' alt="Preview da imagem" />
                      {isFormEditable &&
                        <S.DeleteFab size="small" onClick={() => deleteAvatar()}>
                          <DeleteIcon />
                        </S.DeleteFab>
                      }
                    </>
                  }
                </S.ImageUploadContainer>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Nome do Projeto</p>
              </Grid>
              <S.InputContainer item md={9} xl={9}>
                <S.InputGrid className={isFormEditable ? '' : 'readOnly'}>
                  <S.Input
                    name='title'
                    id='title'
                    placeholder='Nome do Projeto'
                    onChange={formik.handleChange}
                    value={formik.values.title}
                    error={Boolean(formik.errors.title)}
                    helperText={formik.errors.title}
                    InputProps={{
                      readOnly: !isFormEditable
                    }}
                  />
                </S.InputGrid>
              </S.InputContainer>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Empresa</p>
              </Grid>
              <S.InputContainer item md={9} xl={9}>
                <S.InputGrid className={isFormEditable ? '' : 'readOnly'}>
                  <Autocomplete
                    id='lead'
                    readOnly={!isFormEditable}
                    disablePortal
                    value={formik.values?.lead ? formik.values?.lead : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('lead', value)
                    }}
                    options={leadsArr ? leadsArr[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) => <S.Input {...params} placeholder='Empresa' />}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  /*                     filterOptions={(options, params) => {
                                        const filtered = filter(options, params);
                  
                                        filtered.push({
                                          businessName: "Criar nova empresa +", id: "Criar nova empresa +"
                                        });
                  
                                        return filtered;
                                      }} */
                  />
                  {(Boolean(formik.errors.lead)) && <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>
                    {formik.errors.lead}
                  </FormHelperText>}
                </S.InputGrid>
              </S.InputContainer>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Descrição</p>
              </Grid>
              <S.InputContainer item md={9} xl={9}>
                <S.InputGrid className={isFormEditable ? '' : 'readOnly'}>
                  <S.Input
                    multiline
                    placeholder='Descrição do projeto'
                    name='description'
                    id='description'
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: !isFormEditable
                    }}
                  />
                </S.InputGrid>
              </S.InputContainer>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Influencers</p>
              </Grid>
              <S.InputContainer item md={9} xl={9}>
                <S.InputGrid className={isFormEditable ? '' : 'readOnly'}>
                  {!isFormEditable &&
                    <S.Input
                      placeholder='Influencers'
                      value={formik?.values?.influencers ? formik?.values?.influencers?.map((influencer) => influencer.name).join(', ') : ''}
                    />
                  }
                  {isFormEditable &&
                    <Autocomplete
                      disablePortal
                      disableCloseOnSelect
                      multiple
                      readOnly={!isFormEditable}
                      id="influencers"
                      name="influencers"
                      value={formik.values.influencers ? formik.values.influencers : []}
                      onChange={(e, value) => {
                        formik.setFieldValue('influencers', value)
                      }}
                      options={influencerList ? influencerList[0] : []}
                      sx={{ width: "100%" }}
                      renderInput={(params) => <S.Input {...params} placeholder='Influencers' />}
                      getOptionLabel={(option) => option?.name}
                      isOptionEqualToValue={(option, value) => option?.id === value?.id}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.name}
                        </li>
                      )}
                    />
                  }
                </S.InputGrid>
              </S.InputContainer>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Datas</p>
              </Grid>
              <Grid item md={9} xl={9}>
                <S.InputContainer container justifyContent='space-between'>
                  {/*                   <S.DateInputGrid
                    item
                    xs={12}
                    md={5.5}
                    xl={5.5}
                    className='startDate'
                    sx={{ display: isFormEditable ? 'initial' : 'none' }}
                  >
                    <S.Input
                      hiddenLabel
                      type='text'
                      placeholder="Data Inicial"
                      fullWidth
                      value={formik.values.start}
                      data-input
                      autoComplete='off'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <RiCalendarEventFill />
                          </InputAdornment>
                        )
                      }}
                    />
                  </S.DateInputGrid> */}
                  <S.InputGrid
                    className='readOnly'
                    item
                    /* sx={{ display: isFormEditable ? 'none' : 'initial' }} */
                    xs={12}
                    md={12}
                    xl={5.5}
                  >
                    <S.Input
                      placeholder='Data Inicial'
                      name='start'
                      id='start'
                      value={formik.values.start}
                      onChange={formik.handleChange}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </S.InputGrid>
                  {/*                   <S.DateInputGrid
                    item
                    xs={12}
                    md={5.5}
                    xl={5.5}
                    className='startDate'
                    sx={{ display: isFormEditable ? 'initial' : 'none' }}
                  >
                    <S.Input
                      hiddenLabel
                      type='text'
                      placeholder="Data Final"
                      fullWidth
                      value={formik.values.end}
                      data-input
                      autoComplete='off'
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='start'>
                            <RiCalendarEventFill />
                          </InputAdornment>
                        )
                      }}
                    />
                  </S.DateInputGrid> */}
                  <S.InputGrid
                    className='readOnly'
                    item
                    /* sx={{ display: isFormEditable ? 'none' : 'initial' }} */
                    xs={12}
                    md={12}
                    xl={5.5}
                  >
                    <S.Input
                      placeholder='Data Final'
                      name='start'
                      id='start'
                      value={formik.values.end}
                      onChange={formik.handleChange}
                      InputProps={{
                        readOnly: true
                      }}
                    />
                  </S.InputGrid>
                </S.InputContainer>
              </Grid>
            </Grid>
            <Grid container>
              <Grid item md={2.5} xl={2.5}>
                <p>Moeda Corrente</p>
              </Grid>
              <S.InputContainer item md={9} xl={9}>
                <S.InputGrid className='readOnly'>
                  <S.Input
                    placeholder='BRL'
                    name='currency'
                    id='currency'
                    value={formik.values.currency}
                    onChange={formik.handleChange}
                    InputProps={{
                      readOnly: true
                    }}
                  />
                </S.InputGrid>
              </S.InputContainer>
            </Grid>
          </S.FormGrid>
          {isFormEditable &&
            <Grid container justifyContent='flex-end'>
              <ButtonCustom label='Salvar Alterações' sx={{ width: "230px", fontSize: "18px", fontWeight: "500" }} type='submit' />
            </Grid>
          }
        </Form>
      </Formik>
    </S.ProjectCard>
  )
}

export default GeneralForm