import React from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import Table from "defaultComponents/Tables/Table";
import { Grid, Box } from "@mui/material";
import * as S from "./style";
import {
  IoArrowUp,
  IoArrowDown,
  IoEllipse,
  IoArrowBack,
  IoArrowForward,
  IoEllipsisVertical,
} from "react-icons/io5";
import { getProducts, getProductsTags, deleteProduct, editProduct } from "./product";
import filter from "assets/icons/filter.svg";
import FilterModal from "components/Modal/FilterModal";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Checkbox from "@mui/material/Checkbox";
import { SearchBarContext } from "context/SearchBarContext";
import { useDebounce } from "use-debounce";
import { RiAddFill } from "react-icons/ri";
import Modal from "components/Modal/Modal";
import { TokenContext } from "context/TokenContext";
import * as T from "components/Toast/style";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import showToast from "components/Toast/Toast";
import VuiAvatar from "components/VuiAvatar";
import Pagination from '@mui/material/Pagination';
import mockProducts from "./MockProducts/MockProducts";
import CreateProduct from "./ModalContent/CreateProduct";

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="md"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      borderRadius: "15px",
      position: "relative",
    })}
  >
    {children}
  </VuiAvatar>
);

const Products = () => {
  const [products, setProducts] = React.useState([]);
  const [pagination, setPagination] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [tags, setTags] = React.useState();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterValues, setFilterValues] = React.useState([]);
  const [filters, setFilters] = React.useState({ name: null, productTags: [] });
  const [openCreateProductModal, setOpenCreateProductModal] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState([]);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = React.useState(false);
  const [openConfirmStatusChangeModal, setOpenConfirmStatusChangeModal] = React.useState(false);
  const [chosenProduct, setChosenProduct] = React.useState();

  const { searchQuery } = React.useContext(SearchBarContext);
  const { decodedToken } = React.useContext(TokenContext);
  const role = decodedToken?.type;

  const [debouncedSearch] = useDebounce(searchQuery, 600);
  const [debouncedFilters] = useDebounce(filters, 600);

  const fetchTags = async () => {
    const data = await getProductsTags();
    if (!data.statusCode) {
      setTags(data);
      const checkArr = Array.from({ length: data.length }, () => false);
      setFilterValues(checkArr);
    }
  };

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(mockProducts.length / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  const fetchProducts = async () => {
    const data = await getProducts(page - 1, filters);
    if (!data.statusCode) {
      setProducts(data);
    }
  };

  React.useEffect(() => {
    fetchTags();
  }, []);

  React.useEffect(() => {
    fetchProducts();
  }, [page, debouncedFilters]);

  React.useEffect(() => {
    calculatePagination();
  }, [products]);

  React.useEffect(() => {
    setFilters((prevData) => ({
      ...prevData,
      name: debouncedSearch ? debouncedSearch.toUpperCase() : null,
    }));
    setPage(1);
  }, [debouncedSearch]);

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;

  const handleOnChange = (position) => {
    const updatedCheckedState = filterValues.map((item, index) =>
      index === position ? !item : item
    );
    setFilterValues(updatedCheckedState);
  };

  const tagFilter = () => {
    const checkedIndexes = filterValues
      ?.map((bool, index) => (bool === true ? index : null))
      .filter((el) => el !== null);
    const result = checkedIndexes?.map((index) => tags[index].id);
    result.length !== filters?.productTags?.length
      ? setFilters((prevData) => ({ ...prevData, productTags: result }))
      : null;
    setPage(1);
  };

  React.useEffect(() => {
    tagFilter();
  }, [filterValues]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleDelete = (productInfo) => {
    setChosenProduct(productInfo);
    setOpenConfirmDeletionModal(true);
  };

  const cancelDelete = () => {
    setChosenProduct();
    setOpenConfirmDeletionModal(false);
  };

  const handleProductStatus = (productInfo) => {
    setChosenProduct(productInfo);
    setOpenConfirmStatusChangeModal(true);
  };

  const cancelStatusChange = () => {
    setChosenProduct();
    setOpenConfirmStatusChangeModal(false);
  };

  const productStatusChange = async (bool) => {
    await editProduct(chosenProduct?.name, { active: bool }).then((res) => {
      if (!res.message) {
        showToast(
          bool ? "Produto colocado à venda!" : "Produto colocado como não visível!",
          "success"
        );
        fetchProducts();
        cancelStatusChange();
      }
      if (res.message) {
        showToast("Falha ao alterar visibilidade", "error");
      }
    });
  };

  const handleProductDelete = async (name) => {
    await deleteProduct(name).then((res) => {
      if (!res.message) {
        showToast("Produto deletado com sucesso!", "success");
        fetchProducts();
        cancelDelete();
      }
      if (res.message) {
        showToast("Falha ao deletar produto!", "error");
      }
    });
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <VuiBox py={1}>
        <VuiBox mb={1}>
          <S.TableCard>
            <VuiBox display="flex" justifyContent="space-between" alignItems="center" mb="22px">
              <VuiTypography variant="lg" color="white">
                Produtos
              </VuiTypography>
              <S.EditButton onClick={() => setOpenFilter(true)}>
                <img src={filter} draggable="false" />
              </S.EditButton>
            </VuiBox>
            <VuiBox>
              <Table
                mb={role === "ADMINISTRATOR" ? "40px" : 0}
                columns={[
                  { name: "produtos", align: "left" },
                  { name: "categoria", align: "left" },
                  { name: "preço", align: "center" },
                  { name: "estoque", align: "center" },
                  { name: "status", align: "center" },
                  { name: "", align: "center" },
                ]}
                rows={
                  products &&
                  products.map((product, index) => {
                    return {
                      produtos: (
                        <S.ProductGrid
                          container
                          flexDirection="row"
                          // onClick={() =>
                          //   navigate(`/Products/${product?.name ? product?.name : ""}`)
                          // }
                          key={product?.id}
                        >
                          <Grid container item xl={1.5} lg={3} md={2}>
                            <S.RankingPhoto>
                              <Avatar src={product?.avatar ? product?.avatar : ""} />
                            </S.RankingPhoto>
                          </Grid>
                          <S.ProductTextGrid
                            container
                            flexDirection="column"
                            item
                            xl={8.5}
                            lg={7}
                            md={8}
                          >
                            <p className="name">{product.name}</p>
                            <p>
                              {product?.model?.length > 60
                                ? product?.model?.substring(0, 60) + "..."
                                : product?.model}
                            </p>
                          </S.ProductTextGrid>
                        </S.ProductGrid>
                      ),
                      categoria: (
                        <S.ThemeGrid container flexDirection="column">
                          <p className="tags">
                            {product.tags}
                          </p>
                        </S.ThemeGrid>
                      ),
                      preço: (
                        <S.ReachGrid container justifyContent="center">
                          <p>
                            R$ {product.price}
                          </p>
                        </S.ReachGrid>
                      ),
                      estoque: (
                        <S.ReachGrid container justifyContent="center">
                          <p>
                            {product.stock}
                          </p>
                        </S.ReachGrid>
                      ),
                      status: (
                        <S.StatusGrid container justifyContent="center">
                          <IoEllipse color={product?.active ? "#65EFAD" : "#D7007B"} size="12px" />
                          <p className="status">{product?.active ? "À VENDA" : "NÃO VISÍVEL"}</p>
                        </S.StatusGrid>
                      ),
                      "": (
                        <Grid container justifyContent="space-between">
                          {role === "ADMINISTRATOR" && (
                            <Grid
                              item
                              xs={3}
                              md={1}
                              xl={1}
                              sx={{ alignSelf: "center" }}
                              onClick={() => toggleOpenMenu(index)}
                            >
                              <IoEllipsisVertical size="20px" style={{ cursor: "pointer" }} />
                            </Grid>
                          )}
                          {openMenu[index] && (
                            <S.OptionsContainer container justifyContent="flex-end">
                              <S.OptionsGrid display="flex" flexDirection="column">
                                <p>Editar produto</p>
                                <S.Divider />
                                {product?.active && (
                                  <>
                                    <p onClick={() => handleProductStatus(product)}>Mudar visibilidade</p>
                                  </>
                                )}
                                {!product?.active && (
                                  <>
                                    <p onClick={() => handleProductStatus(product)}>Mudar visibilidade</p>
                                  </>
                                )}
                                <S.Divider />
                                <p onClick={() => handleDelete(product)}>Excluir produto</p>
                              </S.OptionsGrid>
                            </S.OptionsContainer>
                          )}
                        </Grid>
                      )
                    };
                  })
                }
              />
            </VuiBox>
          </S.TableCard>
        </VuiBox>
      </VuiBox>
      <Grid container justifyContent="center">
        <S.PaginationGrid container justifyContent="center">
          <Pagination
            count={pagination?.length}
            page={page}
            siblingCount={1}
            boundaryCount={1}
            onChange={handleChange}
          />
        </S.PaginationGrid>
      </Grid>
      {role === "ADMINISTRATOR" && (
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenCreateProductModal(true)}>
            <RiAddFill size="30px" />
          </S.ModalFab>
        </Box>
      )}
      <FilterModal size="sm" open={openFilter} onClose={() => setOpenFilter(false)}>
        <p>Filtragem por categoria</p>
        {tags &&
          tags.map((cat, index) => (
            <Grid container key={index}>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={filterValues[index]}
                onChange={() => handleOnChange(index)}
                name={cat.name}
              />
              <p style={{ fontSize: "18px" }}>{cat.name}</p>
            </Grid>
          ))}
      </FilterModal>
      <Modal open={openCreateProductModal} onClose={() => setOpenCreateProductModal(false)}>
        <CreateProduct />
      </Modal>
      <Modal
        size="sm"
        open={openConfirmDeletionModal}
        onClose={() => setOpenConfirmDeletionModal(false)}
      >
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>Deseja excluir esse produto? Essa ação é irreversível</p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => handleProductDelete(chosenProduct?.name)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size="sm" open={openConfirmStatusChangeModal} onClose={() => cancelStatusChange()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar mudança de visibilidade</p>
          <p style={{ fontSize: "18px" }}>
            Deseja colocar este produto como {chosenProduct?.active ? "NÃO VISÍVEL" : "À VENDA"}?
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelStatusChange()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => productStatusChange(!chosenProduct?.active)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
    </DashboardLayout>
  );
};

export default Products;