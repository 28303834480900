import { useState, useEffect } from "react";
import { Card, Grid, Stack } from "@mui/material";
import balance from "assets/images/balanceBG.png";
import VuiBox from "components/VuiBox";
import VuiTypography from "components/VuiTypography";
import { FaEllipsisH } from "react-icons/fa";
import { IoAlertCircleOutline, IoEllipsisVertical } from "react-icons/io5";
import funds from "assets/icons/funds.svg";
import * as S from "./style";
import moment from "moment";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import Table from "defaultComponents/Tables/Table";
import { RiEyeFill } from "react-icons/ri";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import { BsClockFill } from "react-icons/bs";
import Modal from "components/Modal/Modal";
import { approveWithdraw, rejectWithdraw } from "utils/requests/withdraw";
import { useParams } from "react-router-dom";
import Pagination from "@mui/material/Pagination";
import { getInfluencerFinancial } from "utils/requests/financial";
import showToast from "components/Toast/Toast";
import * as T from "components/Toast/style";
import { getUserWithdrawals } from "utils/requests/withdraw";

const CreditBalance = ({
  openActualModal,
  openFutureModal,
  financialData,
  actualButton,
  futureButton,
}) => {
  const currentBalance = Number(
    ((financialData?.monthlyBalance?.currentBalance ?? 0) / 100).toFixed(2)
  ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  return (
    <Card sx={{ padding: "0", background: "none", backgroundColor: "none" }}>
      <Grid container justifyContent="space-between">
        <Grid display="flex" flexDirection="column" item md={3.8} xl={3.8}>
          <S.CardBox
            p="20px"
            display="flex"
            flexDirection="row"
            sx={{
              backgroundImage: `url(${balance})`,
              backgroundSize: "cover",
              borderRadius: "18px",
            }}
          >
            <Grid>
              <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
                <VuiTypography variant="caption" color="white" fontWeight="medium" mr="auto">
                  Saldo positivo atual
                </VuiTypography>
                {/*             <FaEllipsisH color="white" size="18px" onClick={() => props?.setState(!props.state)} style={{ cursor: "pointer", zIndex: "99" }} />
            {props?.state &&
              <S.OptionsContainer container justifyContent='flex-end'>
                <S.OptionsGrid display='flex' flexDirection='column'>
                  <p onClick={() => props.getPdf()}>Download PDF</p>
                </S.OptionsGrid>
              </S.OptionsContainer>
            } */}
              </VuiBox>
              <VuiBox display="flex" justifyContent="space-beetween" alignItems="center">
                <VuiTypography
                  variant="h2"
                  color="white"
                  fontWeight="bold"
                  mr="auto"
                  sx={{ textWrap: "nowrap" }}
                >
                  R$ {currentBalance ?? 0}
                </VuiTypography>
              </VuiBox>
              <VuiBox display="flex" gap="10px">
                <p style={{ color: "white", opacity: "0.7", fontSize: "16px", marginTop: "2px" }}>
                  Saldo futuro:
                </p>
                <p style={{ color: "#5ACC93", fontSize: "18px" }}>
                  {" "}
                  +R${" "}
                  {Number(
                    ((financialData.monthlyBalance?.pendingBalance ?? 0) / 100).toFixed(2)
                  ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </p>
              </VuiBox>
            </Grid>
          </S.CardBox>
          <Grid container flexDirection="column" gap="10px">
            {/*           {props?.data?.monthlyBalance?.previousMonths?.map((entry, index) => (
            <VuiBox display="flex" justifyContent="space-beetween" alignItems="center" key={entry.month}>
              <Stack direction="row" spacing="10px" mr="auto">
                <VuiBox
                  display="flex"
                  mr="10px"
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    borderRadius: "50%",
                    width: "42px",
                    height: "42px",
                  }}
                >
                  <img src={funds} width='42px' height='42px' />
                </VuiBox>
                <VuiBox display="flex" flexDirection="column">
                  <VuiTypography color="white" variant="button" fontWeight="medium">
                    Balanço de {entry.month} de {entry.year}
                  </VuiTypography>
                </VuiBox>
              </Stack>
              <S.ValueGrid>
                <VuiTypography variant="button" color="white" fontWeight="bold" sx={{ textWrap: "nowrap" }}>
                  R$ {entry.balance ? Number((entry.balance / 100).toFixed(2)).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : '0,00'}
                </VuiTypography>
                <IoEllipsisVertical color="white" onClick={() => props?.toggleOpenMenu(index)} style={{ cursor: 'pointer' }} />
              </S.ValueGrid>
            </VuiBox>
          ))
          } */}
          </Grid>
        </Grid>
        <Grid item md={3.8} xl={3.8}>
          {actualButton?.id && (
            <S.ActiveButton onClick={openActualModal}>
              <div>
                <Grid container justifyContent="center">
                  <BsClockFill
                    color="white"
                    size="22px"
                    style={{ alignSelf: "center", marginRight: "8px" }}
                  />
                  <p className="value">
                    R${" "}
                    {Number(((actualButton?.value ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </Grid>
                <p className="subTitle">Autorizar Solicitação de saque atual</p>
              </div>
            </S.ActiveButton>
          )}
          {!actualButton?.id && (
            <S.DisabledButton disabled>
              <div>
                <p className="subTitle">Saldo Atual</p>
                <p className="info">Não há solicitação em andamento</p>
              </div>
            </S.DisabledButton>
          )}
        </Grid>
        <Grid item md={3.8} xl={3.8}>
          {futureButton?.id && (
            <S.ActiveButton onClick={openFutureModal}>
              <div>
                <Grid container justifyContent="center">
                  <BsClockFill
                    color="white"
                    size="22px"
                    style={{ alignSelf: "center", marginRight: "8px" }}
                  />
                  <p className="value">
                    R${" "}
                    {Number(((futureButton?.value ?? 0) / 100).toFixed(2)).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>
                </Grid>
                <p className="subTitle">Avaliar solicitação de saque futuro</p>
                <p className="info">
                  até 48h para resposta de aprovação ou recusa, com diminuição de 5% do valor.
                </p>
              </div>
            </S.ActiveButton>
          )}
          {!futureButton?.id && (
            <S.DisabledButton disabled>
              <div>
                <p className="subTitle">Saldo Futuro</p>
                <p className="info">Não há solicitação em andamento</p>
              </div>
            </S.DisabledButton>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

const Cashout = () => {
  const [openConfirmActualCashoutModal, setOpenConfirmActualCashoutModal] = useState(false);
  const [openConfirmFutureCashoutModal, setOpenConfirmFutureCashoutModal] = useState(false);
  const [openDetailsModal, setOpenDetailsModal] = useState(false);
  const [openMenu, setOpenMenu] = useState([]);
  const [page, setPage] = useState(1);
  const [financialData, setFinancialData] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [chosenEntry, setChosenEntry] = useState();
  const [cashoutData, setCashoutData] = useState([]);

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const params = useParams();

  const fetchData = async () => {
    await getInfluencerFinancial(params?.username, page - 1, 8, ["OUTCOME"]).then((res) => {
      if (!res?.message) {
        setFinancialData(res);
      }
    });
    await getUserWithdrawals(params?.username, page - 1, 9).then((res) => {
      if (!res?.message) {
        setCashoutData(res);
      }
    });
  };

  const calculatePagination = () => {
    const roundUpArrSize = cashoutData?.withdrawPleas
      ? Math.ceil(cashoutData?.withdrawPleas[1] / 9)
      : 0;
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index);
    setPagination(paginationArr);
  };

  useEffect(() => {
    fetchData();
  }, [page]);

  useEffect(() => {
    if (cashoutData) calculatePagination();
  }, [cashoutData]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const statusColor = {
    APPROVED: "#5ACC93",
    REJECTED: "#FF1A9D",
    PENDING: "#E86A34",
  };

  const statusTranslation = {
    APPROVED: "RESGATE APROVADO",
    REJECTED: "RESGATE RECUSADO",
    PENDING: "EM PROCESSAMENTO",
  };

  const handleActualApproval = async () => {
    await approveWithdraw(cashoutData?.hasPendingWithdraws?.current?.id).then((res) => {
      if (!res.message) {
        showToast("Saque de saldo atual aprovado com sucesso!", "success");
        fetchData();
        setOpenConfirmActualCashoutModal(false);
        return;
      }
      showToast("Erro na aprovação do saque de saldo atual", "error");
    });
  };

  const handleFutureApproval = async () => {
    await approveWithdraw(cashoutData?.hasPendingWithdraws?.future?.id).then((res) => {
      if (!res.message) {
        showToast("Saque de saldo futuro aprovado com sucesso!", "success");
        fetchData();
        setOpenConfirmFutureCashoutModal(false);
        return;
      }
      showToast("Erro na aprovação do saque de saldo futuro", "error");
    });
  };

  const handleFutureRejection = async () => {
    await rejectWithdraw(cashoutData?.hasPendingWithdraws?.future?.id).then((res) => {
      if (!res.message) {
        showToast("Saque de saldo futuro rejeitado com sucesso!", "success");
        fetchData();
        setOpenConfirmFutureCashoutModal(false);
        return;
      }
      showToast("Erro na rejeição do saque de saldo futuro", "error");
    });
  };

  return (
    <DashboardLayout
      onClick={() => (openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null)}
    >
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Grid container flexDirection="column">
        <CreditBalance
          openActualModal={() => setOpenConfirmActualCashoutModal(true)}
          openFutureModal={() => setOpenConfirmFutureCashoutModal(true)}
          financialData={financialData}
          actualButton={cashoutData?.hasPendingWithdraws?.current}
          futureButton={cashoutData?.hasPendingWithdraws?.future}
        />
        <Card sx={{ maxHeight: "580px", overflow: "auto" }}>
          <S.TableGrid container flexDirection="column">
            <p>Histórico</p>
            <Grid container flexDirection="column">
              <Table
                columns={[
                  { name: "status", align: "left" },
                  { name: "valor", align: "left" },
                  { name: "solicitação", align: "left" },
                  { name: "resposta", align: "left" },
                  { name: "observações", align: "left" },
                ]}
                rows={
                  cashoutData?.withdrawPleas &&
                  cashoutData?.withdrawPleas[0]?.map((entry, index) => {
                    return {
                      status: (
                        <Grid container key={index}>
                          <Grid item md={8} xl={9}>
                            <p>{statusTranslation[entry?.status]}</p>
                          </Grid>
                        </Grid>
                      ),
                      valor: (
                        <Grid container>
                          <p style={{ color: statusColor[entry?.status] }}>
                            R${" "}
                            {Number((entry?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </p>
                        </Grid>
                      ),
                      solicitação: (
                        <p>{entry?.createdAt ? moment(entry?.createdAt).format("L") : "-"}</p>
                      ),
                      resposta: (
                        <p>{entry?.resolvedAt ? moment(entry?.resolvedAt).format("L") : "-"}</p>
                      ),
                      observações: (
                        <Grid container flexDirection="column">
                          <Grid container justifyContent="space-between">
                            <S.MiscInfoGrid item xs={11} xl={11}>
                              <p>{entry?.miscInfo}</p>
                            </S.MiscInfoGrid>
                            <Grid item xs={1} xl={1} onClick={() => toggleOpenMenu(index)}>
                              <IoEllipsisVertical
                                size="20px"
                                style={{ cursor: "pointer", zIndex: "9999" }}
                              />
                            </Grid>
                            {openMenu[index] && (
                              <S.OptionsContainer container justifyContent="flex-end">
                                <S.OptionsGrid display="flex" flexDirection="column">
                                  <p
                                    onClick={() => {
                                      setOpenDetailsModal(true);
                                      setChosenEntry(entry);
                                    }}
                                  >
                                    Ver Detalhes
                                  </p>
                                </S.OptionsGrid>
                              </S.OptionsContainer>
                            )}
                          </Grid>
                        </Grid>
                      ),
                    };
                  })
                }
              />
            </Grid>
          </S.TableGrid>
        </Card>
        {pagination?.length > 0 && (
          <S.PaginationGrid container justifyContent="center" sx={{ marginTop: "20px" }}>
            <Pagination
              count={pagination?.length}
              page={page}
              siblingCount={1}
              boundaryCount={1}
              onChange={handleChange}
            />
          </S.PaginationGrid>
        )}
      </Grid>
      <Modal
        size="md"
        open={openConfirmActualCashoutModal}
        onClose={() => setOpenConfirmActualCashoutModal(false)}
      >
        <S.ModalContainer container flexDirection="column" gap="20px">
          <Grid container gap="5px">
            <p className="modalTitle">Autorizar saque de</p>
            <p className="modalTitle bold">saldo atual</p>
          </Grid>
          <p className="cashoutValue">
            R${" "}
            {Number(
              ((cashoutData?.hasPendingWithdraws?.current?.value ?? 0) / 100).toFixed(2)
            ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
          </p>
          <S.AlertGrid container gap="5px">
            <IoAlertCircleOutline size="24px" color="red" style={{ alignSelf: "center" }} />
            <p className="bold">Lembrete: </p>
            <p>Valor precisa ser creditado na conta em até 48h.</p>
          </S.AlertGrid>
          <Grid container justifyContent="flex-end" gap='20px'>
            {cashoutData?.hasPendingWithdraws?.current?.invoiceCertificateURL && (
              <Grid justifyContent="space-between">
                <a
                  href={cashoutData?.hasPendingWithdraws?.current?.invoiceCertificateURL}
                  target="_blank"
                >
                  <ButtonCustom
                    label="Download NF"
                    sx={{
                      width: "150px",
                      height: "40px !important",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  />
                </a>
              </Grid>
            )}
            <ButtonCustom
              label="Autorizar"
              onClick={() => handleActualApproval()}
              sx={{
                width: "150px",
                background: "linear-gradient(180deg, #5ACC93 0%, #027D3F 155.15%)",
                fontSize: "18px",
                fontWeight: 500,
                height: "40px !important",
              }}
            />
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal
        size="md"
        open={openConfirmFutureCashoutModal}
        onClose={() => setOpenConfirmFutureCashoutModal(false)}
      >
        <S.ModalContainer container flexDirection="column" gap="20px">
          <Grid container gap="5px">
            <p className="modalTitle">Autorizar saque de</p>
            <p className="modalTitle bold">saldo futuro</p>
          </Grid>
          <Grid container flexDirection="column" alignContent="center">
            <p className="cashoutValue">
              R${" "}
              {Number(
                ((cashoutData?.hasPendingWithdraws?.future?.value ?? 0) / 100).toFixed(2)
              ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
            </p>
            <p className="discountWarning">
              Taxa de 5% (- R${" "}
              {Number(
                (((cashoutData?.hasPendingWithdraws?.future?.value ?? 0) * 0.05) / 100).toFixed(2)
              ).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
              )
            </p>
          </Grid>
          <S.AlertGrid container gap="5px">
            <IoAlertCircleOutline size="24px" color="red" style={{ alignSelf: "center" }} />
            <p className="bold">Lembrete: </p>
            <p>Valor depende de aprovação e caso aprovado será abatido em 5%.</p>
          </S.AlertGrid>
          <Grid container justifyContent="flex-end" gap="15px">
            <ButtonCustom
              label="Negar"
              onClick={() => handleFutureRejection()}
              sx={{
                width: "150px",
                background: "linear-gradient(180deg, #D04494 0%, #D7004D 155.15%)",
                fontSize: "18px",
                fontWeight: 500,
                height: "40px !important",
              }}
            />
            <ButtonCustom
              label="Autorizar"
              onClick={() => handleFutureApproval()}
              sx={{
                width: "150px",
                background: "linear-gradient(180deg, #5ACC93 0%, #027D3F 155.15%)",
                fontSize: "18px",
                fontWeight: 500,
                height: "40px !important",
              }}
            />
          </Grid>
        </S.ModalContainer>
      </Modal>
      <Modal
        open={openDetailsModal}
        onClose={() => {
          setOpenDetailsModal(false);
          setChosenEntry();
        }}
      >
        <S.DetailsMainGrid container flexDirection="column" gap="15px">
          <p className="titleDetails">Detalhes</p>
          <Grid container gap="20px">
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xs={12} md={5.85} xl={5.85}>
                <p className="subtitleDetails">Nº do processo</p>
                <p className="valueDetails">{chosenEntry?.processNumber}</p>
              </Grid>
              <Grid container item flexDirection="column" xs={12} md={5.85} xl={5.85}>
                <p className="subtitleDetails">Status</p>
                <p className="valueDetails">{statusTranslation[chosenEntry?.status]}</p>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xs={12} md={5.85} xl={5.85}>
                <p className="subtitleDetails">Data da Solicitação</p>
                <p className="valueDetails">
                  {chosenEntry?.createdAt ? moment(chosenEntry?.createdAt).format("L") : ""}
                </p>
              </Grid>
              <Grid container item flexDirection="column" xs={12} md={5.85} xl={5.85}>
                <p className="subtitleDetails">Data da Resposta</p>
                <p className="valueDetails">
                  {chosenEntry?.resolvedAt ? moment(chosenEntry?.resolvedAt).format("L") : ""}
                </p>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xs={12} md={5.85} xl={5.85}>
                <p className="subtitleDetails">Valor</p>
                <p className="valueDetails">
                  R${" "}
                  {Number((chosenEntry?.value / 100).toFixed(2)).toLocaleString("pt-BR", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
              </Grid>
              <Grid container item flexDirection="column" xs={12} md={5.85} xl={5.85}>
                <p className="subtitleDetails">Tipo</p>
                <p className="valueDetails">
                  {chosenEntry?.type === "FUTURE_BALANCE" ? "Saldo Futuro" : "Saldo Atual"}
                </p>
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between">
              <Grid container item flexDirection="column" xs={12} xl={12}>
                <p className="subtitleDetails">Observações</p>
                <p className="valueDetails wrap">{chosenEntry?.miscInfo ?? "-"}</p>
              </Grid>
            </Grid>
            {chosenEntry?.invoiceCertificateURL && (
              <Grid container justifyContent="space-between">
                <a href={chosenEntry?.invoiceCertificateURL} target="_blank">
                  <ButtonCustom
                    label="Download NF"
                    sx={{
                      width: "150px",
                      height: "40px !important",
                      fontSize: "18px",
                      fontWeight: "500",
                    }}
                  />
                </a>
              </Grid>
            )}
          </Grid>
          <S.AlertGrid container gap="5px">
            <IoAlertCircleOutline color="red" style={{ alignSelf: "center" }} />
            <p className="subtitleDetails reminderDetails">Lembrete:</p>
            <p className="subtitleDetails">Valor será creditado na conta em até 48h.</p>
          </S.AlertGrid>
        </S.DetailsMainGrid>
      </Modal>
    </DashboardLayout>
  );
};

export default Cashout;
